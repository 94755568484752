import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Flex,
  Text,
  Box,
} from '@chakra-ui/react';
import FAIcon from '../FAIcon';
import MeditationListDisplay from './MeditationListDisplay';
import AddMeditationForm from './AddMeditationForm';
import IMeditation from '../../modules/meditations/interfaces/IMeditation';
import { useDevice } from 'src/DeviceContext';
import { useModal } from 'src/ModalContext';

interface SuppliedProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
  openAddMeditationModal?: boolean;
  closeAddMeditationModal?: () => void;
}

type Props = SuppliedProps;

const MeditationModal = (props: Props): React.ReactElement => {
  const { t } = useTranslation('meditations');
  const { isOpen, onClose, openAddMeditationModal, closeAddMeditationModal } = props;
  const [selectedMeditation, setSelectedMeditation] = useState<IMeditation | null>(null);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const { isPhone } = useDevice();
  const { notificationType, setNotificationType } = useModal();

  const handleModalClose = () => {
    if (!formIsDirty || window.confirm(t('meditationModal.modalCloseConfirmation'))) {
      if (notificationType === 'meditations') setNotificationType(null);
      setSelectedMeditation(null);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} scrollBehavior="inside" isCentered={true}>
      <ModalOverlay />
      <ModalContent
        maxWidth="700px"
        minHeight="90%"
        marginBottom={isPhone ? [0, 0, 0] : undefined}
        marginTop={isPhone ? [0, 0, 0] : undefined}
        p={[2, 2, 4, 8]}
        zIndex={1900}
      >
        <ModalHeader fontWeight="light" color="purple3.600" fontSize={24} textAlign="center" px={[1, 1, 6]}>
          <FAIcon icon="spa" mr={4} />
          {t('meditationModal.header')}
        </ModalHeader>
        <ModalCloseButton />
        {openAddMeditationModal ? (
          <ModalBody>
            <Flex direction="column" alignItems="left">
              <AddMeditationForm
                selectedMeditation={selectedMeditation}
                onSelectMeditation={(meditation) => setSelectedMeditation(meditation)}
                setFormIsDirty={setFormIsDirty}
                setSelectedMeditation={setSelectedMeditation}
                openAddMeditationModal={openAddMeditationModal}
                closeAddMeditationModal={closeAddMeditationModal}
              />
            </Flex>
          </ModalBody>
        ) : (
          <ModalBody>
            <Box w="50%" mb={5}>
              <Text fontSize={18} mb={5}>
                {t('meditationModal.subheader')}
              </Text>
            </Box>
            <Flex direction="column" alignItems="left">
              <MeditationListDisplay onSelectMeditation={(meditation) => setSelectedMeditation(meditation)} />
              <AddMeditationForm
                selectedMeditation={selectedMeditation}
                onSelectMeditation={(meditation) => setSelectedMeditation(meditation)}
                setFormIsDirty={setFormIsDirty}
                setSelectedMeditation={setSelectedMeditation}
              />
            </Flex>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

export default MeditationModal;
