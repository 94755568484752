import ISurvey from './interfaces/ISurvey';

interface SurveyToFirestore extends Omit<ISurvey, 'id'> {
  id?: string;
}

export const formatSurveyToFirestore = (survey: SurveyToFirestore): Omit<ISurvey, 'id'> => {
  const out: Omit<ISurvey, 'id'> = {
    pir: survey.pir,
    datetime: survey.datetime,
  };

  if (typeof survey.relaxed !== 'undefined') {
    out.relaxed = survey.relaxed;
  }

  if (typeof survey.happy !== 'undefined') {
    out.happy = survey.happy;
  }

  if (typeof survey.stressed !== 'undefined') {
    out.stressed = survey.stressed;
  }

  if (typeof survey.tired !== 'undefined') {
    out.tired = survey.tired;
  }

  if (typeof survey.anxious !== 'undefined') {
    out.anxious = survey.anxious;
  }

  if (typeof survey.sad !== 'undefined') {
    out.sad = survey.sad;
  }

  if (typeof survey.pain !== 'undefined') {
    out.pain = survey.pain;
  }

  if (typeof survey.cravingFor !== 'undefined') {
    out.cravingFor = survey.cravingFor;
  }

  if (typeof survey.cravingFreq !== 'undefined') {
    out.cravingFreq = survey.cravingFreq;
  }

  if (typeof survey.hadCraving !== 'undefined') {
    out.hadCraving = survey.hadCraving;
  }

  if (typeof survey.lastCravingStart !== 'undefined') {
    out.lastCravingStart = survey.lastCravingStart;
  }

  if (typeof survey.lastCravingEnd !== 'undefined') {
    out.lastCravingEnd = survey.lastCravingEnd;
  }

  if (typeof survey.cravingIntensity !== 'undefined') {
    out.cravingIntensity = survey.cravingIntensity;
  }

  if (typeof survey.howHandleCraving !== 'undefined') {
    out.howHandleCraving = survey.howHandleCraving;
  }

  if (typeof survey.numCravingsToday !== 'undefined') {
    out.numCravingsToday = survey.numCravingsToday;
  }

  if (typeof survey.needHelp !== 'undefined') {
    out.needHelp = survey.needHelp;
  }

  if (typeof survey.whatSupportNeeded !== 'undefined') {
    out.whatSupportNeeded = survey.whatSupportNeeded;
  }

  if (typeof survey.additionalInfo !== 'undefined') {
    out.additionalInfo = survey.additionalInfo;
  }

  return out;
};
