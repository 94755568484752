import React, { useState } from 'react';
import { Box, Text, Slider, SliderTrack, SliderThumb, Flex } from '@chakra-ui/react';

export interface NamedSliderProps {
  label: string;
  rank: number;
  referenceLabels: string[];
  updateVal: (val: number) => void;
  interactionCallback?(): void;
  errorLabel: string;
  hasError: boolean;
}

const NamedSlider = ({
  label,
  rank,
  referenceLabels,
  updateVal,
  interactionCallback,
  errorLabel,
  hasError,
}: NamedSliderProps): JSX.Element => {
  const [val, setVal] = useState(rank);

  const update = (val: number) => {
    const scaledVal = scale(val);
    updateVal(scaledVal);
    setVal(scaledVal);
  };

  const addModifiedFieldInteraction = () => {
    if (interactionCallback) {
      interactionCallback();
    }
  };

  const scale = (val: number) => {
    return val / 10;
  };

  return (
    <Box width="100%" marginTop="30px" marginBottom="50px">
      <Box style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
        <Text fontSize="16px" color="#626161" fontWeight={500}>
          {label} *
        </Text>

        {hasError && (
          <Text color="red.500" fontSize="14px" marginTop={'5px'}>
            {errorLabel}
          </Text>
        )}
      </Box>

      <Box width="100%">
        <Slider
          aria-label={label}
          size="lg"
          defaultValue={rank * 10}
          step={10}
          onChange={(val) => update(val)}
          onFocus={addModifiedFieldInteraction}
        >
          <SliderTrack
            borderRadius="10px"
            height={['12px', '13px']}
            backgroundColor="#CCCCCC"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            overflow="visible"
          >
            {[...Array(11)].map((_, i) => (
              <Box key={i} position="relative" display="flex" flexDirection="column" alignItems="center">
                <Box
                  borderRadius={['9px', '11px']}
                  height={['18px', '22px']}
                  width={['18px', '22px']}
                  backgroundColor={i == 0 || i == 10 ? '#575757' : '#CCCCCC'}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize="12px" color={i != 0 && i != 10 ? '#CCCCCC' : '#FFFFFF'} fontWeight={500}>
                    {i}
                  </Text>
                </Box>
              </Box>
            ))}
          </SliderTrack>

          {/* Conditionally render the SliderThumb if rank is not -1 or if clicked */}
          <Box
            position="absolute"
            top="0"
            bottom="0"
            left={['9px', '11px']}
            right={['9px', '11px']}
            opacity={val === -1 ? 0 : 1}
          >
            <SliderThumb
              fontSize="14px"
              backgroundColor="#5C4279"
              color="white"
              zIndex={0}
              fontFamily="body"
              width={['24px', '30px']}
              height={['24px', '30px']}
              fontWeight={700}
              data-test={label}
            >
              {val}
            </SliderThumb>
          </Box>
        </Slider>
      </Box>

      {/* Reference Scale */}
      <Flex width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" mb={['25px', '30px']}>
        {referenceLabels.map((v, i) => (
          <Box
            width="80px"
            textAlign={i === 0 ? 'left' : i === referenceLabels.length ? 'right' : 'center'}
            fontSize="14px"
            color="#626161"
            fontWeight={400}
            key={i}
          >
            {v}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default NamedSlider;
