import {
  setGarmin,
  updateBattery,
  garminSyncStart,
  garminSyncFail,
  garminUploadStart,
  garminUploadFail,
  garminUploadSuccess,
  garminDBStatus,
  garminUploadProgress,
  clearGarmin,
  garminSyncStop,
  addSdkStatus,
  updateSavedSdkStatus,
  updateDeviceIsPairing,
} from '../redux/actions/garmin';
import { store } from '../redux/store';
import Firestore from '../modules/firestore/Firestore';
import * as Sentry from '@sentry/react';
import sizeof from 'firestore-size';
import { serializeError } from 'serialize-error';
import { UpdatedUserAction } from 'src/modules/user/actions';
import IUser, { DevicePairedStatus } from 'src/modules/user/interfaces/IUser';
import { documentRef } from 'src/modules/firebase/firestore';
import firebase from 'firebase/compat/app';
import IGarminSdkStatus, { formatSdkStatusFromFirestore } from 'src/modules/initialize/IGarminSdkStatus';
import { isAndroid } from 'react-device-detect';

export type SavedWearableData = {
  deviceData: any;
  pir: any;
  dataset: any[];
  useNativePersistence: boolean;
  chunkStart: Date;
  chunkEnd: Date;
};

export enum BehaiviorGarminDataType {
  BDTHeartRate = 1,
  BDTBBI = 2,
  BDTSteps = 3,
  BDTStress = 4,
  BDTZeroCrossing = 5,
  BDTPulseOx = 6,
  BDTRespiration = 7,
  BDTAccelerometer = 8,
}

export enum BluetoothStatus {
  UNAUTHORIZED = 'unauthorized',
  BLUETOOTH_ON = 'bluetooth_on',
  BLUETOOTH_NOT_ON = 'bluetooth_not_on',
}

export enum BluetoothAuthState {
  GRANTED = 'granted',
  DENIED_ALWAYS = 'denied_always',
  NOT_REQUESTED = 'not_requested',
  DENIED = 'denied',
  RESTRICTED = 'restricted',
}

export enum BluetoothState {
  BLUETOOTH_ON = 'bluetooth_on',
  BLUETOOTH_NOT_ON = 'bluetooth_not_on',
  BLUETOOTH_DENIED = 'bluetooth_denied',
  BLUETOOTH_NOT_REQUESTED = 'bluetooth_not_requested',
}

export enum NeedsToPairStatus {
  TRYING_TO_PAIR = 'TRYING_TO_PAIR',
  USER_SHOULD_PAIR = 'USER_SHOULD_PAIR',
  DOES_NOT_NEED_TO_PAIR = 'DOES_NOT_NEED_TO_PAIR',
  SHOULD_TRY_TO_PAIR = 'SHOULD_TRY_TO_PAIR',
}

export const isThisAndroid = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /android/.test(userAgent);
};

export const dbCollectionName: { [key: number]: string } = {
  1: 'garminSdkHeartRate',
  2: 'garminSdkBBI',
  3: 'garminSdkSteps',
  4: 'garminSdkStress',
  5: 'garminSdkZeroCrossing',
  6: 'garminSdkPulseOx',
  7: 'garminSdkRespiration',
  8: 'garminSdkAccelerometer',
};

export const lastUpdatedString: { [key: number]: string } = {
  1: 'heartRateLastUpdated',
  2: 'bbiLastUpdated',
  3: 'stepsLastUpdated',
  4: 'stressLastUpdated',
  5: 'zeroCrossingLastUpdated',
  6: 'pulseOxLastUpdated',
  7: 'respirationLastUpdated',
  8: 'accelerometerLastUpdated',
};

const timeout = (ms: number) =>
  new Promise((_, reject) => setTimeout(() => reject(new Error('Operation timed out')), ms));

export const wearableInitializeSDK = async (): Promise<boolean> => {
  console.log('wearableInitializeSDK');
  try {
    // add timeout in case it gets stuck initializing
    const alreadyInitialized = await Promise.race([cordova.plugins.behaiviorGarmin.initializeSDK(), timeout(10000)]);
    console.log('Garmin SDK initialized successfully or timed out and alreadyInitialied is', alreadyInitialized);
    const isAlreadyInitialized = alreadyInitialized === true || alreadyInitialized === 'SDK already initialized';
    console.log('isAlreadyInitialized', isAlreadyInitialized);
    return isAlreadyInitialized;
  } catch (err) {
    Sentry.captureException(new Error(`Error initializing SDK: ${err}`));
    console.error(`Error initializing SDK: ${err}`);
    return false;
  }
};

export const garminPluginInitializeSentry = async (): Promise<void> => {
  console.log('garminPluginInitializeSentry');
  try {
    await cordova.plugins.behaiviorGarmin.initializeSentry();
    console.log('Sentry initialized successfully.');
  } catch (err) {
    Sentry.captureException(new Error(`Error initializing Sentry: ${err}`));
    console.error(`Error initializing Sentry: ${err}`);
  }
};

export const isThisDeviceIdFromSamePlatform = (deviceId: string) => {
  console.log('isThisDeviceIdFromSamePlatform');
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  const bluetoothAddressRegex = /^([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}$/;

  if (isAndroid) {
    if (uuidRegex.test(deviceId)) {
      console.error('Mismatched device ID format: Expected Bluetooth address, got UUID.');
      Sentry.captureMessage(`Mismatched device ID format: Expected UUID, got UUID: ${deviceId}`);
      return false;
    }
    if (!bluetoothAddressRegex.test(deviceId)) {
      console.error('Invalid Bluetooth address format for Android.');
      Sentry.captureMessage(`Invalid Bluetooth address format for Android: ${deviceId}`);
      return false;
    }
  } else {
    if (!uuidRegex.test(deviceId)) {
      console.error('Mismatched device ID format: Expected UUID, got something else.');
      Sentry.captureMessage(`Mismatched device ID format: Expected UUID, got ${deviceId}`);
      return false;
    }
  }

  return true;
};

export const wearablePair = async (
  pirId: string,
  updatePartialUser: (id: string, userUpdates: Partial<IUser>) => Promise<UpdatedUserAction>,
  oldUUID?: string,
): Promise<void> => {
  console.log('wearablePair');
  try {
    // make sure no devices are paired
    store.dispatch(updateDeviceIsPairing(true));
    console.log('unpairDevice');
    await cordova.plugins.behaiviorGarmin.unpairDevice();
    console.log('unpairDevice returned');
    // establish new connection
    console.log('pairDevice');
    console.log('in wearable pair, and pirID is:', pirId, 'old uuid', oldUUID);
    const newUUID = await cordova.plugins.behaiviorGarmin.pairDevice(pirId, oldUUID);
    console.log('pairDevice returned', newUUID);
    Sentry.captureMessage('wearable was paired and will update devicePairedStatus to paired');
    await updatePartialUser(pirId, { devicePairedStatus: DevicePairedStatus.PAIRED });
    addOrUpdateSdkStatusRecord(newUUID);
    store.dispatch(updateDeviceIsPairing(false));
  } catch (err) {
    console.error(`Error pairing Garmin device: ${err}`);
    Sentry.captureException(new Error(`WearablePair failed: ${err}`));
    store.dispatch(updateDeviceIsPairing(false));
    throw err;
  }
};

export const reInitializeSdk = async (): Promise<void> => {
  console.log('reInitializeSdk');
  try {
    await wearableInitializeSDK();
  } catch (err) {
    throw new Error(
      'Error connecting to Garmin. Try closing and reopening your Recovery app, and make sure your device is synced in the Garmin Connect app',
    );
  }
};

// for ios and Android
export const requestBluetoothAuth = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const diagnostic = window.cordova.plugins.diagnostic;
    const permissions = isThisAndroid() ? ['BLUETOOTH_CONNECT', 'BLUETOOTH_SCAN'] : undefined;
    diagnostic.requestBluetoothAuthorization(
      function () {
        resolve();
      },
      function (error: any) {
        console.error(`Error requesting bluetooth authorization for phone: ${error}`);
        reject(error);
      },
      permissions,
    );
  });
};

// for ios and Android
export const checkBluetoothAuthStatus = (): Promise<BluetoothAuthState> => {
  return new Promise((resolve, reject) => {
    const diagnostic = window.cordova.plugins.diagnostic;
    diagnostic.getBluetoothAuthorizationStatus(
      function (bluetoothAuthorized: BluetoothAuthState) {
        resolve(bluetoothAuthorized);
      },
      function (error: any) {
        console.error(`Error getting bluetooth auth status: ${error}`);
        reject(null);
      },
    );
  });
};

// for Android only
export const isBluetoothEnabled = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const diagnostic = window.cordova.plugins.diagnostic;
    diagnostic.isBluetoothEnabled(
      function (bluetoothOn: boolean) {
        resolve(bluetoothOn);
      },
      function (error: any) {
        console.error(`Error getting bluetooth auth status: ${error}`);
        reject(null);
      },
    );
  });
};

// for Android only
export const setBluetoothStatus = (bluetooth: boolean): Promise<void> => {
  return new Promise((resolve, reject) => {
    const diagnostic = window.cordova.plugins.diagnostic;
    diagnostic.setBluetoothState(
      function () {
        resolve();
      },
      function (error: any) {
        console.error(`Error setting bluetooth status for Android: ${error}`);
        reject(error);
      },
      bluetooth,
    );
  });
};

export const goToAppSettings = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const diagnostic = window.cordova.plugins.diagnostic;
    diagnostic.switchToSettings(
      function () {
        resolve();
      },
      function (error: any) {
        console.error(`Error switching to app settings: ${error}`);
        reject(error);
      },
    );
  });
};

export const wearableForget = async (
  pirId: string,
  updatePartialUser: (id: string, userUpdates: Partial<IUser>) => Promise<UpdatedUserAction>,
  userInitiated?: boolean,
): Promise<void> => {
  console.log('wearableForget');
  try {
    await cordova.plugins.behaiviorGarmin.unpairDevice();
    const pairedStatus = userInitiated ? DevicePairedStatus.USER_UNPAIRED : DevicePairedStatus.BECAME_UNPAIRED;
    Sentry.captureMessage(`wearable was forgotten and will update devicePairedStatus to ${pairedStatus}`);
    console.log(`wearable was forgotten and will update devicePairedStatus to ${pairedStatus}`);
    await updatePartialUser(pirId, { devicePairedStatus: pairedStatus });
    store.dispatch(clearGarmin());
  } catch (err) {
    Sentry.captureException(new Error(`Error forgetting wearable: ${err}`));
    console.error(`Error forgetting wearable: ${err}`);
  }
};

export const wearableSync = async (): Promise<void> => {
  console.log('wearableSync');
  // check bluetooth before attempting to sync:
  const garminState = store.getState().garmin;
  if (
    garminState.bluetoothStatus === BluetoothStatus.BLUETOOTH_ON &&
    !garminState.isSyncing &&
    !garminState.isUploading
  ) {
    Sentry.captureMessage('wearable sync is being called from the front end');
    store.dispatch(garminSyncStart());
    try {
      await cordova.plugins.behaiviorGarmin.requestSync();
    } catch (err) {
      const error = `${serializeError(err)}`;
      console.log({ error: error }, 'Wearable sync error');
      Sentry.captureException(new Error(`WearableSync failed: ${error}`));
      if (error === 'Will not attempt to sync because phone is locked') {
        store.dispatch(garminSyncStop());
      } else if (error.includes('notStarted')) {
        store.dispatch(clearGarmin());
      } else if (error === 'pir missing') {
        const message = 'Pir is missing. Will re-pair';
        console.error(message);
        Sentry.captureException(new Error(message));
        store.dispatch(clearGarmin());
      } else {
        store.dispatch(garminSyncFail());
      }

      throw err;
    }
  }
};

export const wearableBackgroundSync = async (): Promise<void> => {
  console.log('wearableBackgroundSync');
  Sentry.captureMessage('trying to run wearable background sync');
  // check bluetooth and that device is paired before attempting to sync:
  const garminState = store.getState().garmin;
  if (garminState.name && garminState.bluetoothStatus === BluetoothStatus.BLUETOOTH_ON) {
    store.dispatch(garminSyncStart());
    try {
      console.log('cordova.plugins.behaiviorGarmin.requestBackgroundSync()');
      Sentry.captureMessage('device is paired and bluetooth is on and about to call background sync in plugin');
      await cordova.plugins.behaiviorGarmin.requestBackgroundSync();
      Sentry.captureMessage('background sync completed');
    } catch (err) {
      console.log({ error: serializeError(err) }, 'Wearable background sync error');
      Sentry.captureException(new Error(`WearableBackgroundSync failed: ${serializeError(err)}`));
      store.dispatch(garminSyncStop());

      throw err;
    }
  }
};

/*
 * This method persist the wearable data to firestore. The useNativePersistence argument is used to control whether the
 * data is persisted to firestore using a native implementation provided by behaivior cordova plugin or firestore lib
 * directly. The plugin is used when running the sync in background mode because the web view used by the background
 * fetch is unable to communicate to firestore when the app is in background for a while.
 * */

const MAX_FIREBASE_DOCUMENT_SIZE = 500 * 1024;
const MAX_FIRESTORE_BATCH_SIZE = 50;

export const uploadWearableData = async (useNativePersistence = false, isBackgroundSync = false): Promise<void> => {
  console.log('uploadWearableData');
  const pir = store.getState().linkedUsers.selectedLinkedUser?.pir;
  const garminState = store.getState().garmin;
  let timeout = false;

  if (!pir) {
    console.log('no pir');
    return;
  }

  if (!garminState) {
    console.log('no garminState');
    return;
  }

  if (garminState.isUploading) {
    console.log('upload already in progress');
    return;
  }

  const endTime = garminState.lastSyncCompleted ? new Date(garminState.lastSyncCompleted) : new Date();
  // let errorStartTime: Date = new Date();
  let startTime: Date = new Date(endTime);
  let lastUploadCompleted: Date | undefined = undefined;

  const dataLastFetched: { [key: string]: Date | undefined } = {
    '1': undefined,
    '2': undefined,
    '3': undefined,
    '4': undefined,
    '5': undefined,
    '6': undefined,
    '7': undefined,
    '8': undefined,
  };

  // for use in the case of error commiting batch to firestore
  const fallbackDataLastFetched: { [key: number]: Date | undefined } = {
    '1': undefined,
    '2': undefined,
    '3': undefined,
    '4': undefined,
    '5': undefined,
    '6': undefined,
    '7': undefined,
    '8': undefined,
  };

  if (garminState.lastUploadCompleted) {
    startTime = new Date(garminState.lastUploadCompleted);
  }

  store.dispatch(garminUploadStart());
  try {
    const previousStatusRecord = await getPreviousSdkStatusRecord(pir, useNativePersistence);
    if (previousStatusRecord) {
      let oldestStartTime = garminState.lastUploadCompleted
        ? new Date(garminState.lastUploadCompleted)
        : previousStatusRecord.endTime;
      for (const dataType of garminState.dataset) {
        const dataLastFetchedType = lastUpdatedString[dataType] as keyof IGarminSdkStatus;
        const dateLastFetched: Date | undefined = previousStatusRecord[dataLastFetchedType] as Date;
        dataLastFetched[dataType] = dateLastFetched;
        fallbackDataLastFetched[dataType] = dateLastFetched;
        console.log(`${dataLastFetchedType} is ${dateLastFetched}`);
        if (dateLastFetched && (dataType == 1 || dataType == 7) && dateLastFetched < oldestStartTime)
          oldestStartTime = dateLastFetched;
      }
      startTime = new Date(oldestStartTime);
    } else {
      console.log('could not find previous sdk status record');
      startTime.setMinutes(startTime.getMinutes() - 15);
    }
  } catch (err) {
    console.error(`Error getting previous SDK status record: ${err}`);
    Sentry.captureException(new Error(`Error getting previous SDK status record: ${err}`));
    return;
  }

  try {
    console.log(`upload started from ${startTime.toISOString()} to ${endTime.toISOString()}`);
    Sentry.captureMessage(`upload started for ${pir.id} from ${startTime.toISOString()} to ${endTime.toISOString()}`);
    const chunks = chunkTimeInterval(startTime, endTime, 3600 * 1000 * 6);
    const uploadStartTime = new Date(startTime);
    console.log(`uploadWearableData: uploading in ${chunks.length} chunks`);
    Sentry.captureMessage(`${pir.id} has ${chunks.length} chunks`);
    for (let i = 0; i < chunks.length; i++) {
      if (store.getState().garmin?.backgroundSyncTimeout) {
        timeout = true;
        console.warn('uploadWearableData: timeout, stopping work gracefully');
        Sentry.captureException(new Error('uploadWearableData: timeout, stopping work gracefully'));
        break;
      }

      const { chunkStart, chunkEnd } = chunks[i];
      console.log(`uploadWearableData: chunk:${i}: getData ${chunkStart}, ${chunkEnd}`);
      Sentry.captureMessage(`About to get data for chunk:${i}: getData ${chunkStart}, ${chunkEnd} for ${pir.id}`);

      try {
        console.log('dataLastFetched', dataLastFetched, JSON.stringify(dataLastFetched));
        const deviceData = await cordova.plugins.behaiviorGarmin.getData(
          chunkStart,
          chunkEnd,
          pir.id,
          uploadStartTime,
          dataLastFetched,
        );
        console.log(`uploadWearableData: chunk:${i}: Storing received data`);

        try {
          await processChunkUpload(
            deviceData,
            pir,
            garminState.dataset,
            useNativePersistence,
            chunkStart,
            chunkEnd,
            dataLastFetched,
            fallbackDataLastFetched,
          );

          const endTime = getEarliestDate(dataLastFetched, garminState.dataset, chunkEnd);
          lastUploadCompleted = endTime;
          store.dispatch(garminUploadProgress((i * 100) / chunks.length, endTime.toISOString()));
        } catch (uploadError) {
          const uploadErrorMessage = `Upload error in wearable data for ${pir.id}`;
          console.log(uploadErrorMessage);
          Sentry.captureException(new Error(uploadErrorMessage));
          // const dataToSave: SavedWearableData = {
          //   deviceData,
          //   pir,
          //   dataset: garminState.dataset,
          //   useNativePersistence,
          //   chunkStart,
          //   chunkEnd,
          // };
          // saveWearableDataLocally(pir.id, dataToSave);
          throw uploadError;
        }
      } catch (error) {
        Sentry.captureException(new Error(`Error with wearable data: ${error}`));
        const errorText = typeof error === 'string' ? error : JSON.stringify(error);
        if (errorText === 'Low memory') {
          console.error(`Failed to upload data due to low memory: ${error}`);
          Sentry.captureException(new Error(`uploadWearableData failed due to low memory: ${error}`));
          store.dispatch(garminUploadFail());
          return;
        } else {
          console.error(`unpload wearable data failed: ${error}`);
          Sentry.captureException(new Error(`unpload wearable data failed: ${error}`));
          store.dispatch(garminUploadFail());
          return;
        }
      }
    }
    console.log('Upload completed successfully');
    Sentry.captureMessage(`Upload completed successfully for ${pir.id}`);
    store.dispatch(garminUploadSuccess());
    // if everything went smoothly, check to see if there is any locally saved data that needs to be uploaded also
    // await checkForSavedDataAndUpload(pir.id);
  } catch (err) {
    console.error(`General failure in uploadWearableData: ${err}`);
    Sentry.captureException(new Error(`uploadWearableData failed: ${err}`));
    store.dispatch(garminUploadFail());
  } finally {
    console.log('Updating SDK status record');
    Sentry.captureMessage('Updating SDK status record');
    await updateSdkStatusRecord(timeout, useNativePersistence, isBackgroundSync, dataLastFetched, lastUploadCompleted);
  }
};

type Chunk = {
  chunkStart: Date;
  chunkEnd: Date;
};

const getEarliestDate = (dataLastFetched: { [key: string]: Date | undefined }, dataset: number[], date: Date) => {
  let oldestUploadEndTime = new Date(date);
  for (const dataType of dataset) {
    const dateLastFetched = dataLastFetched[dataType];
    if (dateLastFetched && (dataType == 1 || dataType == 7) && dateLastFetched < oldestUploadEndTime)
      oldestUploadEndTime = dateLastFetched;
  }
  return oldestUploadEndTime;
};

const processChunkUpload = async (
  deviceData: any,
  pir: any,
  dataset: any[],
  useNativePersistence: boolean,
  chunkStart: Date,
  chunkEnd: Date,
  dataLastFetched: {
    [key: number]: Date | undefined;
  },
  fallbackDataLastFetched: {
    [key: number]: Date | undefined;
  },
): Promise<void> => {
  const batch = useNativePersistence ? null : firebase.firestore().batch();
  let operationsCount = 0;

  try {
    for (const dataType of dataset) {
      try {
        const data = deviceData[dataType];
        const collectionName = dbCollectionName[dataType];
        const size = sizeof(data);

        if (data && data.length > 0) {
          let lastUpdated: Date;
          const endDate = new Date(data[data.length - 1].timestamp);
          const startDate = new Date(data[0].timestamp);
          if (useNativePersistence) {
            Sentry.captureMessage('About to persist data in plugin');
            await persistWearableDataNative(collectionName, data, pir, startDate, endDate, size);
            Sentry.captureMessage('Data persisted in plugin successfully');
            lastUpdated = endDate;
            dataLastFetched[Number(dataType)] = lastUpdated;
          } else if (batch) {
            console.log('about to batch data');
            Sentry.captureMessage('About to batch data', 'warning');
            operationsCount = batchWearableData(
              batch,
              operationsCount,
              collectionName,
              data,
              pir,
              startDate,
              endDate,
              size,
              // Number(dataType),
              // dataLastFetched,
            );
            lastUpdated = endDate;
            dataLastFetched[Number(dataType)] = lastUpdated;

            if (operationsCount >= MAX_FIRESTORE_BATCH_SIZE) {
              Sentry.captureMessage('Committing Firestore batch due to operation limit reached');
              await commitAndResetBatch(batch, dataLastFetched, fallbackDataLastFetched);
              operationsCount = 0;
              Sentry.captureMessage('Batch committed successfully');
            }
          }
        } else {
          if (dataType == 1) {
            Sentry.captureMessage(
              `uploadWearableData: Nothing to store for heartRate from ${chunkStart} to ${chunkEnd}`,
            );
          }
        }
      } catch (innerError) {
        Sentry.captureException(new Error(`Error processing dataType: ${dataType}, error: ${innerError}`));
        console.error(`Error processing dataType: ${dataType}, error: ${innerError}`);
        throw innerError;
      }
    }

    if (batch && operationsCount > 0) {
      Sentry.captureMessage('Committing remaining Firestore batch');
      await commitAndResetBatch(batch, dataLastFetched, fallbackDataLastFetched);
      Sentry.captureMessage('Final batch committed successfully');
    }
  } catch (error) {
    Sentry.captureException(new Error(`Error in processChunkUpload: ${error}`));
    console.error(`Error in processChunkUpload: ${error}`);
    throw error;
  }
};

const commitAndResetBatch = async (
  batch: firebase.firestore.WriteBatch,
  dataLastFetched: {
    [key: number]: Date | undefined;
  },
  fallbackDataLastFetched: {
    [key: number]: Date | undefined;
  },
): Promise<void> => {
  try {
    await batch.commit();
    console.log('Batch committed successfully');
    Sentry.captureMessage('Batch committed successfully');
  } catch (err) {
    console.error(`Error committing batch: ${err}`);
    Sentry.captureException(new Error(`Error committing batch: ${err}`));
    // in the case of error, fall back to original date for data last fetched so we attempt to get the data again later
    for (const key in fallbackDataLastFetched) {
      dataLastFetched[key] = fallbackDataLastFetched[key];
    }
  }
  batch = firebase.firestore().batch();
};

const persistMultipleSubChunksNative = async (
  collectionName: string,
  data: any[],
  pir: any,
  chunkStart: Date,
  chunkEnd: Date,
  size: number,
): Promise<void> => {
  const numChunksNeeded = Math.ceil(size / MAX_FIREBASE_DOCUMENT_SIZE);
  const subChunkSize = Math.floor(data.length / numChunksNeeded);

  console.error(`Number of subchunks needed: ${numChunksNeeded}`);
  Sentry.captureMessage(`Number of subchunks needed: ${numChunksNeeded}`);

  for (let j = 0; j < data.length; j += subChunkSize) {
    const subChunk = data.slice(j, j + subChunkSize);
    try {
      const subChunkStart = subChunk[0].timestamp ? new Date(subChunk[0].timestamp) : chunkStart;
      const subChunkEnd = subChunk[subChunk.length - 1].timestamp
        ? new Date(subChunk[subChunk.length - 1].timestamp)
        : chunkEnd;

      await cordova.plugins.behaiviorGarmin.persistData(collectionName, pir.id, subChunkStart, subChunkEnd, subChunk);
    } catch (err) {
      console.error(`Error persisting native subChunk for ${collectionName}: ${err}`);
      Sentry.captureException(new Error(`Error persisting native subChunk for ${collectionName}: ${err}`));
      throw err;
    }
  }
};

const persistWearableDataNative = async (
  collectionName: string,
  data: any[],
  pir: any,
  chunkStart: Date,
  chunkEnd: Date,
  size: number,
): Promise<void> => {
  try {
    if (size > MAX_FIREBASE_DOCUMENT_SIZE) {
      const sizeMessage = `Size for wearable data is ${size} and so we are further sub-chunking data to upload`;
      console.log(sizeMessage);
      Sentry.captureMessage(sizeMessage);
      await persistMultipleSubChunksNative(collectionName, data, pir, chunkStart, chunkEnd, size);
    } else {
      const sizeMessage = `Size for wearable data is ${size} and so we are uploading it all together`;
      console.log(sizeMessage);
      Sentry.captureMessage(sizeMessage);
      await cordova.plugins.behaiviorGarmin.persistData(collectionName, pir.id, chunkStart, chunkEnd, data);
    }
  } catch (err) {
    console.error(`Error persisting data natively for ${collectionName}: ${err}`);
    Sentry.captureException(new Error(`Error persisting data natively for ${collectionName}: ${err}`));
    throw err;
  }
};

const batchMultipleSubChunks = (
  batch: firebase.firestore.WriteBatch,
  operationsCount: number,
  collectionName: string,
  data: any[],
  pir: any,
  chunkStart: Date,
  chunkEnd: Date,
  size: number,
): number => {
  const subChunkMessage =
    'Chunking data into smaller sub-chunks because the data size exceeds the Firebase document limit.';
  console.log(subChunkMessage);
  Sentry.captureMessage(subChunkMessage);
  const numChunksNeeded = Math.ceil(size / MAX_FIREBASE_DOCUMENT_SIZE);
  console.log('numChunksNeeded', numChunksNeeded);
  Sentry.captureMessage(`numChunksNeeded: ${numChunksNeeded}`);
  const subChunkSize = Math.floor(data.length / numChunksNeeded);

  for (let j = 0; j < data.length; j += subChunkSize) {
    const subChunk = data.slice(j, j + subChunkSize);
    const subChunkSizeEstimate = sizeof(subChunk);
    const subChunkStart = subChunk[0].timestamp ? new Date(subChunk[0].timestamp) : chunkStart;
    const subChunkEnd = subChunk[subChunk.length - 1].timestamp
      ? new Date(subChunk[subChunk.length - 1].timestamp)
      : chunkEnd;
    const batchingMessage = `Storing subChunk ${
      Math.floor(j / subChunkSize) + 1
    }: size estimate ${subChunkSizeEstimate} from ${subChunkStart} to ${subChunkEnd}`;
    Sentry.captureMessage(batchingMessage);
    console.log(batchingMessage);

    batch.set(Firestore.collection(collectionName).doc(), {
      pir,
      startTime: subChunkStart,
      endTime: subChunkEnd,
      data: subChunk,
      dateTime: new Date(),
    });

    operationsCount++;
  }

  return operationsCount;
};

const batchWearableData = (
  batch: firebase.firestore.WriteBatch,
  operationsCount: number,
  collectionName: string,
  data: any,
  pir: firebase.firestore.DocumentReference,
  chunkStart: Date,
  chunkEnd: Date,
  size: number,
  // dataType: number,
  // dataLastFetched: {
  //   [key: number]: Date | undefined;
  // },
) => {
  if (size > MAX_FIREBASE_DOCUMENT_SIZE) {
    const sizeMessage = `Size for wearable data is ${size} and so we are further sub-chunking data to batch`;
    console.log(sizeMessage);
    Sentry.captureMessage(sizeMessage);
    operationsCount = batchMultipleSubChunks(
      batch,
      operationsCount,
      collectionName,
      data,
      pir,
      chunkStart,
      chunkEnd,
      size,
    );
  } else {
    const sizeMessage = `Size for wearable data is ${size} and so we are batching it all together`;
    console.log(sizeMessage);
    Sentry.captureMessage(sizeMessage);
    batch.set(Firestore.collection(collectionName).doc(), {
      pir,
      startTime: chunkStart,
      endTime: chunkEnd,
      data,
      dateTime: new Date(),
    });
    operationsCount++;
    // dataLastFetched[Number(dataType)] = chunkEnd;
  }
  return operationsCount;
};

// const saveWearableDataLocally = (key: string, newData: any) => {
//   const saveDataLocallyMessage = 'Attempting to save data locally because of repeated firestore errors';
//   console.log(saveDataLocallyMessage);
//   Sentry.captureMessage(saveDataLocallyMessage);
//   try {
//     const oldData = localStorage.getItem(key);
//     const dataToSave = [];
//     if (oldData) {
//       dataToSave.push(...oldData, newData);
//     } else {
//       dataToSave.push(newData);
//     }
//     localStorage.setItem(key, JSON.stringify(dataToSave));
//     console.log(`Data saved locally under key: ${key}`);
//   } catch (err) {
//     console.error(`Failed to save data locally: ${err}`);
//     Sentry.captureException(new Error(`Failed to save data locally: ${err}`));
//   }
// };

// export const checkForSavedDataAndUpload = async (pirId: string) => {
//   const locallySavedData: SavedWearableData[] = getDataFromLocalStorage(pirId);
//   if (locallySavedData) {
//     console.log(`There is saved data for ${pirId} -- will try to upload`);
//     Sentry.captureMessage(`There is saved data for ${pirId} -- will try to upload`);
//     try {
//       for (const dataChunk of locallySavedData) {
//         await processChunkUpload(
//           dataChunk.deviceData,
//           dataChunk.pir,
//           dataChunk.dataset,
//           dataChunk.useNativePersistence,
//           dataChunk.chunkStart,
//           dataChunk.chunkEnd,
//         );
//       }
//       const successMessage = `Processed saved data for ${pirId}`;
//       console.log(successMessage);
//       Sentry.captureMessage(successMessage);
//       removeDataFromLocalStorage(pirId);
//     } catch (err) {
//       const failMessage = `Failed to process saved data for ${pirId}`;
//       console.log(failMessage);
//       Sentry.captureMessage(failMessage);
//     }
//   } else {
//     console.log(`There is no saved data to upload for ${pirId}`);
//     Sentry.captureMessage(`There is no saved data to upload for ${pirId}`);
//   }
// };

export const getDataFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

// const removeDataFromLocalStorage = (key: string) => {
//   localStorage.removeItem(key);
// };

const chunkTimeInterval = (startTime: Date, endTime: Date, chunkSize: number): Array<Chunk> => {
  const chunks: Array<Chunk> = [];
  let start = startTime.getTime();
  while (start < endTime.getTime()) {
    const end = Math.min(start + chunkSize, endTime.getTime());
    chunks.push({ chunkStart: new Date(start), chunkEnd: new Date(end) });
    start = end + 1;
  }
  return chunks;
};

export const setupWearableLogging = async (): Promise<void> => {
  console.log('setupWearableLogging');
  try {
    await cordova.plugins.behaiviorGarmin.setupLoggingState();
  } catch (err) {
    console.error('Error setting up wearable logging', err);
    Sentry.captureException(new Error(`Error setting up wearable logging: ${err}`));
    throw err;
  }
};

export const getWearableLogging = async (): Promise<void> => {
  console.log('getWearableLogging');
  try {
    await cordova.plugins.behaiviorGarmin.checkLoggingState();
  } catch (err) {
    console.error('Error getting wearable logging', err);
    Sentry.captureException(new Error(`Error getting wearable logging: ${err}`));
  }
};

export const getWearableBatteryStatus = async (): Promise<void> => {
  console.log('getWearableBatteryStatus');
  try {
    const batteryLevel = await cordova.plugins.behaiviorGarmin.getBatteryStatus();
    if (batteryLevel >= 0) {
      store.dispatch(updateBattery(batteryLevel));
    }
  } catch (err) {
    console.error(`Error getting wearable battery status: ${err}`);
    Sentry.captureException(new Error(`Error getting wearable battery status: ${err}`));

    throw err;
  }
};

export const isDownloadingLoggedData = async (): Promise<boolean> => {
  console.log('isDownloadingLoggedData');
  return (await cordova.plugins.behaiviorGarmin.isDownloadingLoggedData()) == 1 ? true : false;
};

export const getWearableInfo = async (): Promise<{
  friendlyName: string;
  connected: boolean;
}> => {
  console.log('getWearableInfo');
  try {
    const pir = store.getState().linkedUsers.selectedLinkedUser?.pir;
    const deviceInfo = await cordova.plugins.behaiviorGarmin.getPairedDevice(pir?.id);
    if (deviceInfo) {
      store.dispatch(setGarmin(deviceInfo.friendlyName));
    } else {
      throw new Error('No device found');
    }
    return deviceInfo;
  } catch (err) {
    console.error('Error getting wearable info', err);
    Sentry.captureException(new Error(`Error getting wearable info: ${err}`));
    throw err;
  }
};

// Firebase interactions
const addOrUpdateSdkStatusRecord = async (uuid: string): Promise<void> => {
  console.log('addSdkStatusRecord');
  const pirLU = store.getState().linkedUsers.pir;
  const pir = pirLU?.pir;
  const pirPath = pirLU === null ? undefined : pirLU.pir.path;
  if (pir) {
    console.log('pir', pirPath);
    try {
      const previousRecord = await getPreviousSdkStatusRecord(pir);
      const previousRecordId = previousRecord?.id;
      let deviceSdkRef, lastUploadCompleted;
      if (previousRecordId) {
        deviceSdkRef = Firestore.collection('garminSdkStatus').doc(previousRecordId);
        console.log('previousRecord in here', previousRecord.endTime, 'type', typeof previousRecord.endTime);
        const endTime = new Date(previousRecord.endTime);
        lastUploadCompleted = endTime.toISOString();
        await Firestore.collection('garminSdkStatus').doc(previousRecordId).update({ deviceId: uuid });
        store.dispatch(updateSavedSdkStatus({ ...previousRecord, deviceId: uuid }));
      } else {
        deviceSdkRef = Firestore.collection('garminSdkStatus').doc();
        const lastUpload = new Date();
        lastUpload.setMinutes(lastUpload.getMinutes() - 15);
        lastUploadCompleted = lastUpload.toISOString();
        await Firestore.collection('garminSdkStatus')
          .doc(deviceSdkRef.id)
          .set({ deviceId: uuid, pir, endTime: lastUpload, syncErrors: 0 });
        store.dispatch(addSdkStatus({ id: deviceSdkRef.id, deviceId: uuid, pir, endTime: lastUpload, syncErrors: 0 }));
      }
      await store.dispatch(garminDBStatus(deviceSdkRef.path, pirPath, lastUploadCompleted));
    } catch (err) {
      console.error(`Error adding or updating SDK status record" ${err}`);
      Sentry.captureException(new Error(`Error adding or updating SDK status record" ${err}`));
    }
  } else {
    console.log('no pir in addOrUpdateSdkStatusRecord');
  }
};

export const getPreviousSdkStatusRecord = async (
  pir: firebase.firestore.DocumentReference | undefined,
  useNativePersistence = false,
): Promise<IGarminSdkStatus | undefined> => {
  console.log('getprevioussdkstatusrecord');

  const garminState = store.getState().garmin;
  if (garminState.savedSdkStatus) {
    console.log('garmin saved', garminState.savedSdkStatus.endTime);
    console.log('garmin saved', garminState.savedSdkStatus, garminState.savedSdkStatus.id);
    return garminState.savedSdkStatus;
  } else if (pir) {
    if (useNativePersistence) {
      try {
        console.log('INSIDE USE NATIVE PERSISTENCE IN PREVIOUS SDK STATUS RECORD');
        const result = await cordova.plugins.behaiviorGarmin.getPreviousSdkStatus(pir.path);
        if (result.status === 'empty') {
          console.log('cannot find previus sdk status record');
          return undefined;
        }
        const deviceId = result.deviceId !== null ? result.deviceId : undefined;
        const endTime = new Date(result.endTime);
        const heartRateLastUpdated = result.heartRateLastUpdated ? new Date(result.heartRateLastUpdated) : undefined;
        const bbiLastUpdated = result.bbiLastUpdated ? new Date(result.bbiLastUpdated) : undefined;
        const stepsLastUpdated = result.stepsLastUpdated ? new Date(result.stepsLastUpdated) : undefined;
        const stressLastUpdated = result.stressLastUpdated ? new Date(result.stressLastUpdated) : undefined;
        const zeroCrossingLastUpdated = result.zeroCrossingLastUpdated
          ? new Date(result.zeroCrossingLastUpdated)
          : undefined;
        const pulseOxLastUpdated = result.pulseOxLastUpdated ? new Date(result.pulseOxLastUpdated) : undefined;
        const respirationLastUpdated = result.respirationLastUpdated
          ? new Date(result.respirationLastUpdated)
          : undefined;
        const accelerometerLastUpdated = result.accelerometerLastUpdated
          ? new Date(result.accelerometerLastUpdated)
          : undefined;
        const pirRef = result.pir ? result.pir : undefined;
        const id = result.id;
        const syncErrors = result.syncErrors;

        const previousGarminDevice: IGarminSdkStatus = {
          id,
          deviceId,
          syncErrors,
          endTime,
          heartRateLastUpdated,
          bbiLastUpdated,
          stepsLastUpdated,
          stressLastUpdated,
          zeroCrossingLastUpdated,
          pulseOxLastUpdated,
          respirationLastUpdated,
          accelerometerLastUpdated,
          pir: pirRef,
        };

        store.dispatch(addSdkStatus(previousGarminDevice));
        return previousGarminDevice;
      } catch (err) {
        console.error(`Error getting previous status record in plugin: ${err}`);
      }
    } else {
      console.log('I AM DOING THIS FROM THE FRONT END IN PREVIOUS SDK STATUS RECORD');
      try {
        const previousGarminDeviceSnapshot = await Firestore.database()
          .collection('garminSdkStatus')
          .where('pir', '==', pir)
          .get();

        if (previousGarminDeviceSnapshot.empty) {
          console.log('cannot find previus sdk status record');
          return undefined;
        }
        const previousGarminDevice: IGarminSdkStatus = formatSdkStatusFromFirestore(
          previousGarminDeviceSnapshot.docs[0],
        );

        store.dispatch(addSdkStatus(previousGarminDevice));
        return previousGarminDevice;
      } catch (err) {
        console.error(`Error getting previous SDK status record: ${err}`);
        Sentry.captureException(new Error(`Error getting previous SDK status record: ${err}`));
      }
    }
  } else {
    return undefined;
  }
};

export const checkIfUserWasPreviouslyPaired = async (userId: string) => {
  try {
    const pirDocRef = documentRef('users', userId);
    const previousGarminDevice = await Firestore.database()
      .collection('garminSdkStatus')
      .where('pir', '==', pirDocRef)
      .get();
    if (!previousGarminDevice.empty) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error(`Error checking if user was previously paired: ${err}`);
  }
};

const updateSdkStatusRecord = async (
  timeout: boolean,
  useNativePersistence: boolean,
  isBackgroundSync: boolean,
  dataLastFetched: {
    [key: number]: Date | undefined;
  },
  lastUploadCompleted: Date | undefined,
): Promise<void> => {
  try {
    const garmin = store.getState().garmin;
    console.log('updateSdkStatusRecord');

    if (garmin.id && garmin.pir) {
      const now = new Date();
      const endTime = lastUploadCompleted
        ? lastUploadCompleted
        : garmin.lastUploadCompleted
        ? new Date(garmin.lastUploadCompleted)
        : now;
      console.log(`Updating SDK status record for ${garmin.pir} with end time of ${endTime}`);
      Sentry.captureMessage(`SDKstatus ${garmin.pir} ET: ${endTime}`);

      const updates: { [key: string]: any } = {
        endTime,
        syncErrors: garmin.syncErrors,
        dateTime: new Date(),
      };
      // updating dataLastFetched for each dataType to add to sdkStatusRecord
      for (const key in dataLastFetched) {
        if (dataLastFetched[key]) {
          const field = lastUpdatedString[key];
          updates[field] = dataLastFetched[key];
        } else {
          console.log('there was no data for this key');
        }
      }
      if (useNativePersistence) {
        try {
          await cordova.plugins.behaiviorGarmin.updateSdkStatus(
            garmin.id,
            garmin.pir,
            endTime,
            garmin.syncErrors,
            timeout,
            dataLastFetched,
          );
          store.dispatch(updateSavedSdkStatus(updates));
        } catch (err) {
          console.error('updateSdkStatusRecord: error updating record in plugin', err);
          Sentry.captureException(new Error(`updateSdkStatus reccord error: ${err}`));
        }
      } else {
        try {
          const pirDocRef = Firestore.database().doc(garmin.pir);
          const dataDocRef = Firestore.database().doc(garmin.id);
          const executionLogDocRef = dataDocRef.collection('syncExecutionLogs').doc();

          const batch = Firestore.database().batch();

          batch.update(dataDocRef, updates);
          batch.set(executionLogDocRef, {
            pir: pirDocRef,
            endTime,
            executionTime: now,
            task: isBackgroundSync ? 'backgroundSync' : 'sync',
            platform: window.device.platform,
            syncErrors: garmin.syncErrors,
            timeout,
          });
          await batch.commit();
          store.dispatch(updateSavedSdkStatus(updates));
        } catch (err) {
          console.error('updateSdkStatusRecord: Firestore batch error', err);
          Sentry.captureException(new Error(`updateSdkStatusRecord firestore batch error: ${err}`));
        }
      }
    } else {
      console.warn('updateSdkStatusRecord: Missing garmin ID or PIR');
    }
  } catch (err) {
    console.error('updateSdkStatusRecord: Unexpected error', err);
    Sentry.captureException(new Error(`updateSdkStatusRecord unexpected error: ${err}`));
  }
};
