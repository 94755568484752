import { NewTaskActionTypes, NewTaskActions } from './actions';

interface NewTaskState {
  isNewTaskModuleOpen: boolean;
}

const initialState: NewTaskState = {
  isNewTaskModuleOpen: false,
};

const newTaskReducer = (state = initialState, action: NewTaskActions): NewTaskState => {
  switch (action.type) {
    case NewTaskActionTypes.OPEN_NEW_TASK_MODULE:
      return { ...state, isNewTaskModuleOpen: true };
    case NewTaskActionTypes.CLOSE_NEW_TASK_MODULE:
      return { ...state, isNewTaskModuleOpen: false };
    case NewTaskActionTypes.SET_NEW_TASK_MODULE_STATE:
      return { ...state, isNewTaskModuleOpen: action.isOpen };
    default:
      return state;
  }
};

export default newTaskReducer;
