import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TodosItem from '../../components/TodosItem';
import { Box, Flex, Heading, Grid, Progress, Text } from '@chakra-ui/react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getToDos, setSelectedToDo, updateToDo } from './actions';
import { useModal } from 'src/ModalContext';
import { AppointmentsContext } from '../interior/Index';
import { AppointmentStatus, AppointmentType } from '../appointments/interfaces/IAppointment';
import NewTaskModule from '../new-todo/NewTaskModule';
import useIsVisible from 'src/hooks/useIsVisible';

interface Props {
  isCP?: boolean;
  pirId?: string;
}

const TodoModule = ({
  isCP, //  pir,
  updateToDo,
  setSelectedToDo,
  userTimezone,
  pirId,
  getToDos,
}: Props & PropsFromRedux): React.ReactElement => {
  const history = useHistory();
  const todos = useSelector((state: RootState) => state.todo.todos);
  const todosLastReset = useSelector((state: RootState) => state.todo.todosReset);
  const { openModal } = useModal();
  const { setAppointmentTodos, setMeditationTodos, appointmentTodos, meditationTodos } = useModal();
  const { ref, isVisible } = useIsVisible();

  const { appointments } = useContext(AppointmentsContext);

  const checkIfNeedToResetTodos = async (id: string) => {
    const currentTime = Date.now();
    const minutesSinceLastRun = todosLastReset ? (currentTime - todosLastReset) / (1000 * 60) : null;
    if (minutesSinceLastRun === null || minutesSinceLastRun > 59) {
      console.log('Resetting todos because it has been at least an hour');
      await getToDos(id);
    }
  };

  useEffect(() => {
    if (pirId) {
      setInterval(() => checkIfNeedToResetTodos(pirId), 1000 * 60 * 60);
    }
  }, []);

  useEffect(() => {
    if (todos?.find((todo) => todo.type === 'appointments')) {
      if (appointmentTodos === false) setAppointmentTodos(true);
    } else if (appointmentTodos === true) {
      setAppointmentTodos(false);
    }
    if (todos?.find((todo) => todo.type === 'meditations ')) {
      if (meditationTodos === false) setMeditationTodos(true);
    } else if (meditationTodos === true) {
      setMeditationTodos(false);
    }
  }, [todos, todos?.length]);

  const todosPercentCompleted = (): number => {
    if (todos && todos.length > 0) {
      // 'device' todos not factored into progress
      const filteredTodos = todos.filter((todo) => todo.type !== 'device');
      if (filteredTodos.length > 0) {
        const completedTodos = filteredTodos.filter((todo) => todo.isCompleted).length;
        return Math.floor((completedTodos / filteredTodos.length) * 100);
      }
      return -1;
    }
    return -1;
  };

  const showProgressBar = (): boolean => {
    return todosPercentCompleted() !== -1;
  };

  const progressPercent = todosPercentCompleted();

  return (
    <Box w="100%" ref={ref}>
      <Flex as="header" mb={4} align="center">
        <Heading as="h3" fontSize={18} fontWeight="bold" color="#4F4F4F">
          Today&apos;s Tasks
        </Heading>
        <NewTaskModule isVisible={isVisible} />
      </Flex>

      {showProgressBar() && (
        <Box my="16px" color="purple3.600" overflow="hidden">
          <Progress
            height="23px"
            maxW="100%"
            value={progressPercent}
            borderRadius="12px"
            colorScheme="purple4"
            backgroundColor="#A48CC066"
          />
          <Text float="right" fontSize={14} fontWeight="bold">
            {`${progressPercent}% Completed`}
          </Text>
        </Box>
      )}

      <Grid
        templateColumns={
          isCP
            ? ['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)']
            : ['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']
        }
        gap={4}
      >
        {todos
          ? todos.map((todo, idx) => {
              let appointmentStatus, appointmentStartTime;
              if (todo.type === 'appointments') {
                const appointment = appointments?.find((appointment) => appointment.id === todo.history?.split('/')[1]);
                appointmentStatus =
                  appointment?.appointmentStatus === AppointmentStatus.CONFIRMED &&
                  appointment?.appointmentTime.timeEnd > new Date()
                    ? AppointmentType.UPCOMING
                    : appointment?.appointmentStatus === AppointmentStatus.CONFIRMED
                    ? AppointmentType.PAST
                    : appointment?.appointmentStatus === AppointmentStatus.PENDING &&
                      appointment?.appointmentTime.timeEnd > new Date() &&
                      ((isCP && appointment?.approvedByPir) || (!isCP && appointment?.approvedByProvider))
                    ? AppointmentType.CONFIRM
                    : appointment?.appointmentStatus === AppointmentStatus.PENDING &&
                      appointment?.appointmentTime.timeEnd > new Date() &&
                      ((!isCP && appointment?.approvedByPir) || (isCP && appointment?.approvedByProvider))
                    ? AppointmentType.PENDING
                    : null;

                appointmentStartTime = appointment?.appointmentTime.timeStart;
              }

              // if the appointment isn't confirmed or past or pending/to-confirm (it was cancelled or is improperly formatted) don't show the todo
              if (appointmentStatus === null) return null;

              return (
                <TodosItem
                  width="100%"
                  fontSizes={[16, 16, 18]}
                  isCP={isCP}
                  todo={todo}
                  key={'todo-idx-' + idx}
                  userTimezone={userTimezone}
                  appointmentStatus={appointmentStatus}
                  appointmentStartTime={appointmentStartTime}
                  onClick={() => {
                    if (todo.history) {
                      // Would need to adjust to add a query parmeter mark this as a todo task?
                      history.push('/home/' + todo.history + '?todo=1');
                      setSelectedToDo(todo);
                      switch (todo.type) {
                        case 'survey':
                        case 'medications':
                        case 'meditations':
                        case 'sleep':
                        case 'appointments':
                          openModal(todo.type);

                          break;

                        default:
                          openModal('single');
                          break;
                      }
                    }
                  }}
                  onChangeCheckbox={(checked) => {
                    if (todo.canClick) {
                      todo.isCompleted = !todo.isCompleted;
                      if (todo.isCompleted) {
                        todo.completedDateTime = new Date();
                      } else {
                        todo.completedDateTime = undefined;
                      }
                      updateToDo(todo, todos);
                      return !checked;
                    }
                    return checked;
                  }}
                />
              );
            })
          : null}
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  // if (todos === null) {
  //   throw new Error("Todos should't be null!");
  // }

  const { selectedLinkedUser } = state.linkedUsers;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }

  return {
    pir: selectedLinkedUser.pir,
    userTimezone: state.user?.user?.timezone,
  };
};

const mapDispatchToProps = {
  getToDos,
  updateToDo,
  setSelectedToDo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TodoModule);
