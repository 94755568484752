import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import FAIcon from '../../components/FAIcon';
import EmergencyAlert from './EmergencyAlert';
import ModalAlert from './ModalAlert';
import { isPirLinkedUser } from '../linked-users/utils';
import { RootState } from '../../redux/store';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkedUserRole } from '../linked-users/interfaces/ILinkedUser';
import { UserRole, DevicePairedStatus } from '../user/interfaces/IUser';
import { BluetoothStatus, NeedsToPairStatus } from 'src/lib/wearable';
import { useDevice } from 'src/DeviceContext';
import dayjs from 'dayjs';

const FooterNav = (props: PropsFromRedux): JSX.Element | null => {
  const emergencyDisclosure = useDisclosure();
  const modalAlertDisclosure = useDisclosure();
  const { t } = useTranslation('common');
  const { linkedUser, user } = props;
  const location = useLocation();
  const [userType, setUserType] = useState<string>('');
  const isTabActive = (tabName: string) => location.pathname.startsWith('/' + tabName);
  const { isCordova, isMobileDevice } = useDevice();
  const isDeviceVisible = isCordova && LinkedUserRole.PIR;
  const garminDevice = useSelector((state: RootState) => state.garmin);
  const bluetoothStatus = useSelector((state: RootState) => state.garmin.bluetoothStatus);
  const deviceIsPaired = !!useSelector((state: RootState) => state.garmin.name);
  const deviceIsConnected = useSelector((state: RootState) => state.garmin.deviceIsConnected);
  const needsToPairStatus = useSelector((state: RootState) => state.garmin.needsToPair);
  const isSyncingOrUploading = garminDevice.isSyncing || garminDevice.isUploading;
  const bluetoothIsOffAndDevicePaired: boolean = deviceIsPaired && bluetoothStatus !== BluetoothStatus.BLUETOOTH_ON;
  const devicePairedButNotConnected: boolean = deviceIsPaired && !deviceIsConnected;
  const needsToRePair = needsToPairStatus === NeedsToPairStatus.USER_SHOULD_PAIR;
  const lastSynced = useSelector((state: RootState) => state.garmin.lastUploadCompleted);
  const now = dayjs();
  const syncStale =
    user?.devicePairedStatus &&
    user.devicePairedStatus !== DevicePairedStatus.USER_UNPAIRED &&
    lastSynced &&
    now.diff(dayjs(lastSynced), 'hour') >= 12;
  const needsToSync =
    !(bluetoothIsOffAndDevicePaired || devicePairedButNotConnected || needsToRePair || isSyncingOrUploading) &&
    syncStale;

  useEffect(() => {
    const type =
      (user?.role as UserRole) === UserRole.ADMIN
        ? 'admin'
        : linkedUser?.pir?.id === user?.id
        ? 'pir'
        : linkedUser?.pir?.id !== user?.id || (user?.role as UserRole) === UserRole.CP
        ? 'cp'
        : '';
    setUserType(type);
    return () => {
      setUserType('');
    };
  }, [linkedUser]);

  return (
    <Box
      display={!isMobileDevice ? ['block', 'none', 'none'] : 'block'}
      backgroundColor="white"
      px="32px"
      pb={'max(8px, env(safe-area-inset-bottom))'}
      boxShadow="0px -2px 5px 0px rgba(0, 0, 0, 0.1)"
      zIndex={1}
    >
      <Flex alignItems="center" justifyContent="space-between" height="100%">
        {userType === 'admin' && (
          <Box
            minWidth="70px"
            textAlign="center"
            fontSize="md"
            fontWeight="bold"
            color={isTabActive('invitations') ? 'purple3.600' : 'grey3.400'}
          >
            <Link to="/home">
              <FAIcon
                icon="users"
                aria-label={t('footerNav.invitations')}
                display="block"
                textAlign="center"
                fontSize={24}
                borderTop="4px solid"
                paddingTop="8px"
                borderColor={isTabActive('home') ? 'currentColor' : 'transparent'}
                borderTopLeftRadius="3px"
                borderTopRightRadius="3px"
              />
              {t('footerNav.invitations')}
            </Link>
          </Box>
        )}
        {userType === 'pir' && (
          <Box
            minWidth="70px"
            textAlign="center"
            fontSize="md"
            fontWeight="bold"
            color={isTabActive('home') ? 'purple3.600' : 'grey3.400'}
          >
            <Link to="/home">
              <FAIcon
                icon="home"
                aria-label="Home"
                display="block"
                textAlign="center"
                fontSize={24}
                borderTop="4px solid"
                paddingTop="8px"
                borderColor={isTabActive('home') ? 'currentColor' : 'transparent'}
                borderTopLeftRadius="3px"
                borderTopRightRadius="3px"
              />
              {t('footerNav.home')}
            </Link>
          </Box>
        )}
        {userType === 'cp' && (
          <Box
            minWidth="70px"
            textAlign="center"
            fontSize="md"
            fontWeight="bold"
            color={isTabActive('dashboard') ? 'purple3.600' : 'grey3.400'}
          >
            <Link to="/dashboard">
              <FAIcon
                icon="users"
                aria-label="Vitals"
                display="block"
                fontSize={24}
                borderTop="4px solid"
                paddingTop="8px"
                borderColor={isTabActive('dashboard') ? 'currentColor' : 'transparent'}
                borderTopLeftRadius="3px"
                borderTopRightRadius="3px"
              />
              {t('footerNav.list')}
            </Link>
          </Box>
        )}
        {userType === 'cp' && (
          <Box
            minWidth="70px"
            textAlign="center"
            fontSize="md"
            fontWeight="bold"
            color={isTabActive('profile') ? 'purple3.600' : 'grey3.400'}
          >
            <Link to="/profile">
              <FAIcon
                icon="user"
                aria-label="Vitals"
                display="block"
                borderTop="4px solid"
                paddingTop="8px"
                fontSize={24}
                borderColor={isTabActive('profile') ? 'currentColor' : 'transparent'}
                borderTopLeftRadius="3px"
                borderTopRightRadius="3px"
              />
              {t('footerNav.profile')}
            </Link>
          </Box>
        )}
        {(userType === 'cp' || userType === 'pir') && (
          <Box
            minWidth="70px"
            textAlign="center"
            fontSize="md"
            fontWeight="bold"
            color={isTabActive('vitals') ? 'purple3.600' : 'grey3.400'}
          >
            <Link to="/vitals">
              <FAIcon
                icon="chart-bar"
                aria-label="Vitals"
                display="block"
                borderTop="4px solid"
                paddingTop="8px"
                fontSize={24}
                borderColor={isTabActive('vitals') ? 'currentColor' : 'transparent'}
                borderTopLeftRadius="3px"
                borderTopRightRadius="3px"
              />
              {t('footerNav.vital')}
            </Link>
          </Box>
        )}
        {/* Emergency button if PIR */}
        {linkedUser && userType === 'pir' && (
          <Box minWidth="70px" textAlign="center" fontSize="md" fontWeight="bold" color="grey3.400">
            <FAIcon
              icon="exclamation-circle"
              as="div"
              display="block"
              textAlign="center"
              borderTop="4px solid transparent"
              paddingTop="8px"
              fontSize={24}
              onClick={isPirLinkedUser(linkedUser) ? modalAlertDisclosure.onOpen : emergencyDisclosure.onOpen}
            />
            {t('footerNav.alert')}
          </Box>
        )}

        {isDeviceVisible && linkedUser && userType === 'pir' && (
          <Box
            minWidth="70px"
            textAlign="center"
            fontSize="md"
            fontWeight="bold"
            color={isTabActive('device') ? 'purple3.600' : 'grey3.400'}
          >
            <Link to="/device">
              <Box position="relative" display="inline-block">
                <FAIcon
                  icon="sync-alt"
                  display="block"
                  textAlign="center"
                  borderTop="4px solid"
                  color={'currentColor'}
                  paddingTop="8px"
                  fontSize={24}
                  borderColor={isTabActive('device') ? 'currentColor' : 'transparent'}
                  borderTopLeftRadius="3px"
                  borderTopRightRadius="3px"
                />
                {(bluetoothIsOffAndDevicePaired || devicePairedButNotConnected || needsToRePair) && (
                  <FAIcon icon="exclamation-circle" position="absolute" top="2" right="1" fontSize="12px" color="red" />
                )}
                {needsToSync && (
                  <FAIcon
                    icon="exclamation-circle"
                    position="absolute"
                    top="2"
                    right="1"
                    fontSize="12px"
                    color="orange"
                  />
                )}
                {t('footerNav.device')}
              </Box>
            </Link>
          </Box>
        )}
      </Flex>

      <EmergencyAlert isOpen={emergencyDisclosure.isOpen} onClose={emergencyDisclosure.onClose} />
      <ModalAlert isOpen={modalAlertDisclosure.isOpen} onClose={modalAlertDisclosure.onClose} source="alert" />
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    linkedUser: state.linkedUsers.selectedLinkedUser,
    user: state.user.user,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FooterNav);
