import React, { useState, useEffect } from 'react';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Center } from '@chakra-ui/layout';
import {
  Box,
  Button,
  Select,
  Flex,
  Text,
  Textarea,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@chakra-ui/accordion';

import 'react-datepicker/dist/react-datepicker.css'; // options to display both date and time
import NamedSlider from '../NamedSlider';
import PrimaryRadioGroup from '../PrimaryRadioGroup';
import { RootState } from '../../redux/store';
import { createSurvey } from '../../modules/survey/actions';
import { addAlert } from '../../modules/user/actions';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import { useHistory } from 'react-router-dom';
import { getToDos, updateToDo } from '../../modules/todo/actions';
import FAIcon from '../FAIcon';
import DiscreteDatePicker from '../DiscreteDatePicker';

import { setActivityInProgress, processNotificationQueue } from './../../modules/firebase/messaging';
import { useModal } from '../../ModalContext';

interface FormValues {
  relaxed?: number;
  happy?: number;
  stressed?: number;
  tired?: number;
  anxious?: number;
  sad?: number;
  pain?: number;
  cravingFor?: string;
  cravingFreq?: string;
  hadCraving?: string;
  lastCravingStart: Date;
  lastCravingEnd: Date;
  cravingIntensity?: number;
  howHandleCraving?: string;
  numCravingsToday?: number;
  needHelp?: string;
  whatSupportNeeded?: string;
  additionalInfo?: string;
}

interface Props {
  onClose(): void;
  onFocusCallback?(): void;
  onBlurCallback?(): void;
}

const DEFAULT_RANK = -1; // scale for ranking feelings: 0-10
const DEFAULT_REFERENCE_LABELS = ['None', 'Moderate', 'Extreme'];
const SECTION_HEADING_SIZE = ['16px', '18px'];
const SECTION_HEADING_WEIGHT = [700, 500];
const DESCR_FONT_SIZE = ['16px', '16px'];
const TODAY_DATE = new Date();
const MAX_CHARS = 1000; // stay within Firebase limits
const SUBHEADING_PROPS = {
  fontSize: '16px',
  fontWeight: 500,
  color: '#626161',
};

const SurveyForm = ({
  todos,
  updateToDo,
  createSurvey,
  pir,
  addAlert,
  onClose,
  createInteraction,
  hasOnboarded,
  onFocusCallback,
  onBlurCallback,
}: PropsFromRedux & Props): React.ReactElement => {
  const { t } = useTranslation(['survey', 'common']);
  const history = useHistory();
  const toast = useToast();
  const selectedTodo = useSelector((state: RootState) => state.todo.selectedToDo);
  const DEFAULT_CRAVING_FREQ = t('surveyModal.sectionCravings.cravingFrequencyOptions.once_per_hour');

  const { register, handleSubmit, formState, control } = useForm<FormValues>({
    defaultValues: {
      relaxed: DEFAULT_RANK,
      happy: DEFAULT_RANK,
      stressed: DEFAULT_RANK,
      tired: DEFAULT_RANK,
      anxious: DEFAULT_RANK,
      sad: DEFAULT_RANK,
      pain: DEFAULT_RANK,
      cravingFor: '',
      cravingFreq: DEFAULT_CRAVING_FREQ,
      hadCraving: 'No',
      lastCravingStart: new Date(),
      lastCravingEnd: new Date(),
      cravingIntensity: DEFAULT_RANK,
      howHandleCraving: '',
      numCravingsToday: 0,
      needHelp: 'No',
      whatSupportNeeded: '',
      additionalInfo: '',
    },
  });

  const { errors } = formState;
  const [relaxedVal, setRelaxedVal] = useState(DEFAULT_RANK);
  const [happyVal, setHappyVal] = useState(DEFAULT_RANK);
  const [stressedVal, setStressedVal] = useState(DEFAULT_RANK);
  const [tiredVal, setTiredVal] = useState(DEFAULT_RANK);
  const [anxiousVal, setAnxiousVal] = useState(DEFAULT_RANK);
  const [sadVal, setSadVal] = useState(DEFAULT_RANK);
  const [painVal, setPainVal] = useState(DEFAULT_RANK);
  const [cravingIntensity, setCravingIntensity] = useState(DEFAULT_RANK);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cravingFor, setCravingFor] = useState('');
  const [cravingForLen, setCravingForLen] = useState(0);
  const [cravingForLenInvalid, setCravingForLenInvalid] = useState(false);
  const [cravingFreq, setCravingFreq] = useState(DEFAULT_CRAVING_FREQ);
  const [hasCraved, setHasCraved] = useState('');
  const [numCravings, setNumCravings] = useState(0);
  const [needSupport, setNeedSupport] = useState('no');
  const [cravingStart, setCravingStart] = useState(new Date());
  const [cravingStartInvalid, setCravingStartInvalid] = useState(false);
  const [cravingEnd, setCravingEnd] = useState(new Date());
  const [cravingEndInvalid, setCravingEndInvalid] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [howHandleCraving, setHowHandleCraving] = useState('');
  const [howHandleCravingLen, setHowHandleCravingLen] = useState(0);
  const [howHandleCravingLenInvalid, setHowHandleCravingLenInvalid] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [whatSupportNeeded, setWhatSupportNeeded] = useState('');
  const [whatSupportNeededLen, setWhatSupportNeededLen] = useState(0);
  const [whatSupportNeededLenInvalid, setWhatSupportNeededLenInvalid] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [additionalInfoLen, setAdditionalInfoLen] = useState(0);
  const [additionalInfoLenInvalid, setAdditionalInfoLenInvalid] = useState(false);
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [hasCravingError, setHasCravingError] = useState(false);
  const { openModal, notificationType, setNotificationType } = useModal();

  const [hasRelaxedError, setHasRelaxedError] = useState(false);
  const [hasHappyError, setHasHappyError] = useState(false);
  const [hasStressedError, setHasStressedError] = useState(false);
  const [hasTiredError, setHasTiredError] = useState(false);
  const [hasAnxiousError, setHasAnxiousError] = useState(false);
  const [hasSadError, setHasSadError] = useState(false);
  const [hasPainError, setHasPainError] = useState(false);

  const cravingFreqChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCravingFreq(event.target.value);
    addModifiedFieldInteraction();
  };

  const hasCravedChanged = (value: string) => {
    setHasCraved(value);
    setHasCravingError(false);
    addModifiedFieldInteraction();
  };

  const needSupportChanged = (value: string) => {
    setNeedSupport(value);
    addModifiedFieldInteraction();
  };

  const checkTextLen = (value: string) => {
    return value.length > MAX_CHARS; // check if text length within limit
  };

  const handleCravingForText = (value: string) => {
    setCravingFor(value);
    setCravingForLen(value.length);
    const invalidTextLen = checkTextLen(value);
    setCravingForLenInvalid(invalidTextLen);
    setFormHasErrors(invalidTextLen);
  };

  const handleHowHandleCravingText = (value: string) => {
    setHowHandleCraving(value);
    setHowHandleCravingLen(value.length);
    const invalidTextLen = checkTextLen(value);
    setHowHandleCravingLenInvalid(invalidTextLen);
    setFormHasErrors(invalidTextLen);
  };

  const handleSupportNeededText = (value: string) => {
    setWhatSupportNeeded(value);
    setWhatSupportNeededLen(value.length);
    const invalidTextLen = checkTextLen(value);
    setWhatSupportNeededLenInvalid(invalidTextLen);
    setFormHasErrors(invalidTextLen);
  };

  const handleAdditionalInfoText = (value: string) => {
    setAdditionalInfo(value);
    setAdditionalInfoLen(value.length);
    const invalidTextLen = checkTextLen(value);
    setAdditionalInfoLenInvalid(invalidTextLen);
    setFormHasErrors(invalidTextLen);
  };

  const triggerSurveyTodo = () => {
    if (todos && selectedTodo) {
      if (!selectedTodo.isCompleted && selectedTodo.type === 'survey') {
        selectedTodo.completedDateTime = new Date();
        selectedTodo.isCompleted = true;
        updateToDo(selectedTodo, todos);
      }
    }
  };

  const modalDisclosure = useDisclosure();

  useEffect(() => {
    //Check if the slider values are set
    if (relaxedVal !== -1) setHasRelaxedError(false);
    if (happyVal !== -1) setHasHappyError(false);
    if (stressedVal !== -1) setHasStressedError(false);
    if (tiredVal !== -1) setHasTiredError(false);
    if (anxiousVal !== -1) setHasAnxiousError(false);
    if (sadVal !== -1) setHasSadError(false);
    if (painVal !== -1) setHasPainError(false);
  }, [relaxedVal, happyVal, stressedVal, tiredVal, anxiousVal, sadVal, painVal]);

  useEffect(() => {
    setActivityInProgress(true);
    if (
      cravingForLenInvalid ||
      howHandleCravingLenInvalid ||
      whatSupportNeededLenInvalid ||
      additionalInfoLenInvalid ||
      cravingStartInvalid ||
      cravingEndInvalid
    ) {
      setFormHasErrors(true); // cannot submit survey if form has errors (invalid input)
    } else setFormHasErrors(false);
    return () => {
      setActivityInProgress(false);
      processNotificationQueue(history, openModal, notificationType, setNotificationType, modalDisclosure);
    };
  }, [
    cravingForLenInvalid,
    howHandleCravingLenInvalid,
    whatSupportNeededLenInvalid,
    additionalInfoLenInvalid,
    cravingStartInvalid,
    cravingEndInvalid,
  ]);

  const saveSurvey = (survey: FormValues) => {
    if (relaxedVal === -1) setHasRelaxedError(true);
    if (happyVal === -1) setHasHappyError(true);
    if (stressedVal === -1) setHasStressedError(true);
    if (tiredVal === -1) setHasTiredError(true);
    if (anxiousVal === -1) setHasAnxiousError(true);
    if (sadVal === -1) setHasSadError(true);
    if (painVal === -1) setHasPainError(true);
    if (hasCraved !== 'yes' && hasCraved !== 'no') setHasCravingError(true);

    if (
      relaxedVal === -1 ||
      happyVal === -1 ||
      stressedVal === -1 ||
      tiredVal === -1 ||
      anxiousVal === -1 ||
      sadVal === -1 ||
      painVal === -1 ||
      (hasCraved !== 'yes' && hasCraved !== 'no')
    )
      return;

    // save survey in database
    createSurvey({
      pir,
      datetime: new Date(), // date of submission = current date
      relaxed: relaxedVal,
      happy: happyVal,
      stressed: stressedVal,
      tired: tiredVal,
      anxious: anxiousVal,
      sad: sadVal,
      pain: painVal,
      cravingFor: survey.cravingFor,
      cravingFreq,
      hadCraving: hasCraved === 'yes',
      lastCravingStart: cravingStart,
      lastCravingEnd: cravingEnd,
      cravingIntensity,
      howHandleCraving: survey.howHandleCraving,
      numCravingsToday: numCravings,
      needHelp: needSupport === 'yes',
      whatSupportNeeded,
      additionalInfo,
    })
      .then(() => {
        toast({
          title: t('surveyResponse.surveySent'),
          description: t('surveyResponse.surveyLogged'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        triggerSurveyTodo();
        onClose();
      })
      .catch((e) => {
        console.error(e);
        toast({
          title: t('surveyResponse.surveyNotSent'),
          description: t('surveyResponse.surveyError'),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.SURVEY,
      interactionType: interactionType.SURVEY.SAVED,
    });
    setFormSubmitted(true); // display message to user so they know if form has been submitted
    // setFormHasErrors(true); // disable submit button
    // if PIR indicates that they need help, send alert to care provider
    if (needSupport === 'yes') addAlert('warnProvider');
    //onClose();
    if (!hasOnboarded) {
      history.push('/device');
    }
  };

  const changeRelaxedRank = (val: number) => {
    setRelaxedVal(val);
  };

  const changeHappyRank = (val: number) => {
    setHappyVal(val);
  };

  const changeStressedRank = (val: number) => {
    setStressedVal(val);
  };

  const changeTiredRank = (val: number) => {
    setTiredVal(val);
  };

  const changeAnxiousRank = (val: number) => {
    setAnxiousVal(val);
  };

  const changeSadRank = (val: number) => {
    setSadVal(val);
  };

  const changePainRank = (val: number) => {
    setPainVal(val);
  };

  const changeCravingIntensity = (val: number) => {
    setCravingIntensity(val);
  };

  const changeNumCravings = (val: React.ReactText) => {
    setNumCravings(Number(val)); // parse string as number (ex: '123' -> 123)
    addModifiedFieldInteraction();
  };

  const addModifiedFieldInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.SURVEY,
      interactionType: interactionType.SURVEY.MODIFIED_FIELD,
    });
  };

  const interactionalCallback = () => {
    // Update input focused state
    if (onFocusCallback) {
      onFocusCallback();
    }

    // Track user interaction
    addModifiedFieldInteraction();
  };

  return (
    <Box padding={0}>
      <form autoComplete="off" onSubmit={handleSubmit(saveSurvey)}>
        {/* Feelings Section */}
        <Flex direction="column" px="9px">
          <Flex direction="column" paddingTop="10px" paddingBottom="10px">
            <Text textAlign="left" color="#626161" fontSize={SECTION_HEADING_SIZE} fontWeight={SECTION_HEADING_WEIGHT}>
              {t('surveyModal.sectionFeelings.sectionHeading')}
            </Text>
            <Text textAlign="left" color="#8F8F8F" fontSize={DESCR_FONT_SIZE} fontWeight={300}>
              {t('surveyModal.sectionFeelings.sectionInstruct')}
            </Text>
          </Flex>
          <Flex direction="row" pb={4} display="block" paddingTop="10px" paddingBottom="10px">
            <NamedSlider
              label={t('surveyModal.sectionFeelings.feelingsOptions.relaxed')}
              rank={DEFAULT_RANK}
              updateVal={changeRelaxedRank}
              interactionCallback={addModifiedFieldInteraction}
              referenceLabels={DEFAULT_REFERENCE_LABELS}
              hasError={hasRelaxedError}
              errorLabel={t('surveyModal.feelingErrors.relaxed')}
            />

            <NamedSlider
              label={t('surveyModal.sectionFeelings.feelingsOptions.happy')}
              rank={DEFAULT_RANK}
              updateVal={changeHappyRank}
              interactionCallback={addModifiedFieldInteraction}
              referenceLabels={DEFAULT_REFERENCE_LABELS}
              hasError={hasHappyError}
              errorLabel={t('surveyModal.feelingErrors.happy')}
            />

            <NamedSlider
              label={t('surveyModal.sectionFeelings.feelingsOptions.stressed')}
              rank={DEFAULT_RANK}
              updateVal={changeStressedRank}
              interactionCallback={addModifiedFieldInteraction}
              referenceLabels={DEFAULT_REFERENCE_LABELS}
              hasError={hasStressedError}
              errorLabel={t('surveyModal.feelingErrors.stressed')}
            />

            <NamedSlider
              label={t('surveyModal.sectionFeelings.feelingsOptions.tired')}
              rank={DEFAULT_RANK}
              updateVal={changeTiredRank}
              interactionCallback={addModifiedFieldInteraction}
              referenceLabels={DEFAULT_REFERENCE_LABELS}
              hasError={hasTiredError}
              errorLabel={t('surveyModal.feelingErrors.tired')}
            />

            <NamedSlider
              label={t('surveyModal.sectionFeelings.feelingsOptions.anxious')}
              rank={DEFAULT_RANK}
              updateVal={changeAnxiousRank}
              interactionCallback={addModifiedFieldInteraction}
              referenceLabels={DEFAULT_REFERENCE_LABELS}
              hasError={hasAnxiousError}
              errorLabel={t('surveyModal.feelingErrors.anxious')}
            />

            <NamedSlider
              label={t('surveyModal.sectionFeelings.feelingsOptions.sad')}
              rank={DEFAULT_RANK}
              updateVal={changeSadRank}
              interactionCallback={addModifiedFieldInteraction}
              referenceLabels={DEFAULT_REFERENCE_LABELS}
              hasError={hasSadError}
              errorLabel={t('surveyModal.feelingErrors.sad')}
            />

            <NamedSlider
              label={t('surveyModal.sectionFeelings.feelingsOptions.pain')}
              rank={DEFAULT_RANK}
              updateVal={changePainRank}
              interactionCallback={addModifiedFieldInteraction}
              referenceLabels={DEFAULT_REFERENCE_LABELS}
              hasError={hasPainError}
              errorLabel={t('surveyModal.feelingErrors.pain')}
            />
          </Flex>
        </Flex>

        <Accordion allowMultiple={true} overflow="visible" defaultIndex={[0]}>
          <Divider orientation="horizontal" borderColor="#E5E5E5" />

          {/* Cravings Section */}
          <AccordionItem border="none" data-test="cravings">
            {({ isExpanded }) => (
              <>
                {/*disable hover styles*/}
                <AccordionButton pt="20px" px="9px" _hover={{}}>
                  <Box
                    flex="1"
                    textAlign="left"
                    color="#626161"
                    fontSize={SECTION_HEADING_SIZE}
                    fontWeight={SECTION_HEADING_WEIGHT}
                  >
                    {t('surveyModal.sectionCravings.sectionHeading')}
                  </Box>
                  <FAIcon
                    icon="caret-down"
                    color="#626161"
                    fontSize="27px"
                    transition="0.3s"
                    transform={isExpanded ? 'rotate(-180deg);' : 'rotate(0deg)'}
                  />
                </AccordionButton>
                {hasCravingError && (
                  <Text color="red.500" fontSize="14px" px={'9px'}>
                    {t('surveyModal.sectionCravings.cravingError')}
                  </Text>
                )}

                <AccordionPanel px="9px">
                  <Box mt={['7px', '20px']} mb={3}>
                    <Text {...SUBHEADING_PROPS}>{t('surveyModal.sectionCravings.cravedDuringStudyPrompt')}</Text>
                    <PrimaryRadioGroup
                      name="hasCraved"
                      onChange={hasCravedChanged}
                      value={hasCraved}
                      defaultValue=""
                      options={[
                        ['yes', t('surveyForm.yes')],
                        ['no', t('surveyForm.no')],
                      ]}
                    />
                  </Box>
                  {hasCraved === 'yes' ? (
                    <Box>
                      <Box>
                        <Text {...SUBHEADING_PROPS} mb="24px">
                          {t('surveyModal.sectionCravings.cravingForPrompt')}
                        </Text>
                        <Textarea
                          isInvalid={cravingForLenInvalid}
                          minHeight={['127px']}
                          borderRadius="10px"
                          border="2px solid #E5E5E5"
                          focusBorderColor="#E5E5E5"
                          resize="none"
                          color="#626161"
                          fontSize={['14px', '16px']}
                          placeholder={t('surveyModal.sectionCravings.cravingForPlaceholder')}
                          {...register('cravingFor', {
                             maxLength: MAX_CHARS,
                          })}
                          onChange={(e: any) => handleCravingForText(e.target.value)}
                          onFocus={addModifiedFieldInteraction}
                          data-test="craving-for-text"
                          />
                        <Text
                          fontSize={16}
                          mb={2}
                          textAlign="right"
                          color={cravingForLenInvalid ? 'red.400' : 'grey.400'}>
                          {cravingForLen} / {MAX_CHARS}
                        </Text>
                      </Box>
                      <Box mb={3}>
                        <Text {...SUBHEADING_PROPS} mb="24px">
                          {t('surveyModal.sectionCravings.cravingFrequencyPrompt')}
                        </Text>
                        <Select
                          maxWidth="310px"
                          height="29px"
                          borderRadius="5px"
                          value={cravingFreq}
                          onChange={cravingFreqChanged}
                          fontWeight={400}
                          fontSize="16px"
                          color="#626161"
                          border="1px solid #E5E5E5"
                          iconColor="#C4C4C4"
                          iconSize="23px"
                          icon={<FAIcon icon="caret-down" alignItems="center" justifyContent="center" display="flex" />}
                          data-test="cravings-freq-select"
                        >
                          <option value="multi_per_hour">
                            {t('surveyModal.sectionCravings.cravingFrequencyOptions.multi_per_hour')}
                          </option>
                          <option value="once-per-hour">
                            {t('surveyModal.sectionCravings.cravingFrequencyOptions.once_per_hour')}
                          </option>

                          <option value="every-few-hours">
                            {t('surveyModal.sectionCravings.cravingFrequencyOptions.every_few_hours')}
                          </option>
                          <option value="once-per-day">
                            {t('surveyModal.sectionCravings.cravingFrequencyOptions.once_per_day')}
                          </option>
                          <option value="once-per-week">
                            {t('surveyModal.sectionCravings.cravingFrequencyOptions.once_per_week')}
                          </option>
                          <option value="multi-per-week">
                            {t('surveyModal.sectionCravings.cravingFrequencyOptions.multi_per_week')}
                          </option>
                          <option value="random">
                            {t('surveyModal.sectionCravings.cravingFrequencyOptions.random')}
                          </option>
                        </Select>
                      </Box>
                      <Box mb={['39px', '34px']}>
                        <Text {...SUBHEADING_PROPS} my="24px">
                          {t('surveyModal.sectionCravings.lastCraving.lastCravingPrompt')}
                        </Text>
                        <Flex direction="row" mb={['17px', '20px']}>
                          <Flex direction="column" mr={['19px', '21px']}>
                            <Text {...SUBHEADING_PROPS} mb={2}>
                              {t('surveyModal.sectionCravings.lastCraving.cravingStart')}
                            </Text>
                          </Flex>
                          <Flex direction="column" fontSize="16px" fontWeight={400} color="#626161">
                            <Controller
                              name="lastCravingStart"
                              control={control}
                              render={({
                                field: { onChange, onBlur },
                              }: {
                                field: ControllerRenderProps<FormValues, 'lastCravingStart'>;
                              }) => (
                                <DiscreteDatePicker
                                  onBlur={onBlur}
                                  onDateChange={(date: Date | null, invalid: boolean) => {
                                    if (!invalid && date) {
                                      onChange(date);
                                      setCravingStart(date);
                                      setCravingStartInvalid(invalid);
                                      addModifiedFieldInteraction();
                                    }
                                  }}
                                  currDate={cravingStart}
                                  maxYear={TODAY_DATE.getFullYear() + 1}
                                />
                              )}
                            />
                          </Flex>
                        </Flex>
                        <Flex direction="row">
                          <Flex direction="column" mr={['27px', '29px']}>
                            <Text {...SUBHEADING_PROPS} mb={2}>
                              {t('surveyModal.sectionCravings.lastCraving.cravingEnd')}
                            </Text>
                          </Flex>
                          <Flex direction="column" fontSize="16px" fontWeight={400} color="#626161">
                            <Controller
                              name="lastCravingEnd"
                              control={control}
                              render={({
                                field: { onChange, onBlur },
                              }: {
                                field: ControllerRenderProps<FormValues, 'lastCravingEnd'>;
                              }) => (
                                <DiscreteDatePicker
                                  onBlur={onBlur}
                                  onDateChange={(date: Date | null, invalid: boolean) => {
                                    if (!invalid && date) {
                                      onChange(date);
                                      setCravingEnd(date);
                                      setCravingEndInvalid(invalid);
                                      addModifiedFieldInteraction();
                                    }
                                  }}
                                  currDate={cravingEnd}
                                  maxYear={TODAY_DATE.getFullYear() + 1}
                                />
                              )}
                            />
                          </Flex>
                        </Flex>
                      </Box>
                      <NamedSlider
                        label={t('surveyModal.sectionCravings.lastCraving.lastCravingIntensity')}
                        rank={DEFAULT_RANK}
                        updateVal={changeCravingIntensity}
                        interactionCallback={addModifiedFieldInteraction}
                        referenceLabels={DEFAULT_REFERENCE_LABELS}
                        hasError={hasCravingError}
                        errorLabel={t('surveyModal.feelingErrors.cravings')}
                      />
                      <Box>
                        <Text {...SUBHEADING_PROPS} mb="24px">
                          {t('surveyModal.sectionCravings.lastCraving.howHandle')}
                        </Text>
                        <Textarea
                          isInvalid={howHandleCravingLenInvalid}
                          minHeight={['127px']}
                          borderRadius="10px"
                          border="2px solid #E5E5E5"
                          focusBorderColor="#E5E5E5"
                          resize="none"
                          color="#626161"
                          fontSize={['14px', '16px']}
                          placeholder={t('surveyModal.sectionCravings.lastCraving.howHandlePlaceholder')}
                          {...register('howHandleCraving', {
                            maxLength: MAX_CHARS,
                          })}
                          onChange={(e: any) => handleHowHandleCravingText(e.target.value)}
                          onFocus={addModifiedFieldInteraction}
                          data-test="handle-cravings-text"
                        />
                        <Text
                          fontSize={16}
                          mb={2}
                          textAlign="right"
                          color={howHandleCravingLenInvalid ? 'red.400' : 'grey.400'}
                        >
                          {howHandleCravingLen} / {MAX_CHARS}
                        </Text>
                      </Box>
                      <Box>
                        <Text {...SUBHEADING_PROPS} mb={2}>
                          {t('surveyModal.sectionCravings.numCravingsToday')}
                        </Text>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          step={1}
                          height="29px"
                          width="90px"
                          border="1px solid #E5E5E5"
                          focusBorderColor="#E5E5E5"
                          boxShadow="none"
                          borderRadius="5px"
                          value={numCravings}
                          onChange={changeNumCravings}
                          mt="26px"
                          mb="38px"
                          fontFamily="body"
                          color="#626161"
                          data-test="number-cravings"
                        >
                          <NumberInputField border="none" height="100%" />
                          <NumberInputStepper width="23px" padding="1px" height="100%" margin="0px" bottom="0">
                            <VStack
                              width="100%"
                              height="100%"
                              overflow="hidden"
                              alignItems="stretch"
                              justifyContent="space-between"
                            >
                              <NumberIncrementStepper
                                flex="1"
                                maxH="12px"
                                minH="0"
                                color="#C4C4C4"
                                backgroundColor="#E5E5E5"
                              />
                              <NumberDecrementStepper
                                flex="1"
                                maxH="12px"
                                minH="0"
                                color="#C4C4C4"
                                backgroundColor="#E5E5E5"
                              />
                            </VStack>
                          </NumberInputStepper>
                        </NumberInput>
                      </Box>
                    </Box>
                  ) : (
                    <Box />
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <Divider orientation="horizontal" borderColor="#E5E5E5" />
          {/* Support Section */}
          <AccordionItem border="none" data-test="support">
            {({ isExpanded }) => (
              <>
                {/*disable hover styles*/}
                <AccordionButton py="20px" px="9px" _hover={{}}>
                  <Box
                    flex="1"
                    color="#626161"
                    textAlign="left"
                    fontSize={SECTION_HEADING_SIZE}
                    fontWeight={SECTION_HEADING_WEIGHT}
                  >
                    {t('surveyModal.sectionSupport.sectionHeading')}
                  </Box>
                  <FAIcon
                    icon="caret-down"
                    color="#626161"
                    fontSize="27px"
                    transition="0.3s"
                    transform={isExpanded ? 'rotate(-180deg);' : 'rotate(0deg)'}
                  />
                </AccordionButton>
                <AccordionPanel px="9px">
                  <Box mb={3}>
                    <Text {...SUBHEADING_PROPS} mt={['10px', '27px']}>
                      {t('surveyModal.sectionSupport.needHelpPrompt')}
                    </Text>
                    <PrimaryRadioGroup
                      name="needSupport"
                      onChange={needSupportChanged}
                      value={needSupport}
                      defaultValue="no"
                      options={[
                        ['yes', t('surveyForm.yes')],
                        ['no', t('surveyForm.no')],
                      ]}
                    />
                  </Box>
                  {needSupport === 'yes' ? (
                    <Box>
                      <Box mb={['26px', '18px']}>
                        <Text {...SUBHEADING_PROPS} mb={['21px', '36px']}>
                          {t('surveyModal.sectionSupport.kindOfSupportNeeded')}
                        </Text>
                        <Textarea
                          isInvalid={whatSupportNeededLenInvalid}
                          minHeight={['127px']}
                          borderRadius="10px"
                          border="2px solid #E5E5E5"
                          focusBorderColor="#E5E5E5"
                          resize="none"
                          color="#626161"
                          fontSize={['14px', '16px']}
                          placeholder={t('surveyModal.sectionSupport.kindOfSupportNeededPlaceholder')}
                          {...register('whatSupportNeeded', {
                            maxLength: MAX_CHARS,
                          })}
                          onChange={(e: any) => handleSupportNeededText(e.target.value)}
                          onFocus={interactionalCallback}
                          onBlur={onBlurCallback}
                          data-test="what-support-needed-text"
                        />
                        <Text
                          fontSize={16}
                          mb={2}
                          textAlign="right"
                          color={whatSupportNeededLenInvalid ? 'red.400' : 'grey.400'}
                        >
                          {whatSupportNeededLen} / {MAX_CHARS}
                        </Text>
                      </Box>
                      <Box mb={['53px', '40px']}>
                        <Text {...SUBHEADING_PROPS} mb={['21px', '36px']}>
                          {t('surveyModal.sectionSupport.anythingElse')}
                        </Text>
                        <Textarea
                          isInvalid={additionalInfoLenInvalid}
                          minHeight={['127px']}
                          borderRadius="10px"
                          border="2px solid #E5E5E5"
                          focusBorderColor="#E5E5E5"
                          resize="none"
                          color="#626161"
                          fontSize={['14px', '16px']}
                          placeholder={t('surveyModal.sectionSupport.anythingElsePlaceholder')}
                          {...register('additionalInfo', {
                            maxLength: MAX_CHARS,
                          })}
                          onChange={(e: any) => handleAdditionalInfoText(e.target.value)}
                          onFocus={interactionalCallback}
                          onBlur={onBlurCallback}
                          data-test="anything-else-text"
                        />
                        <Text
                          fontSize={16}
                          mb={2}
                          textAlign="right"
                          color={additionalInfoLenInvalid ? 'red.400' : 'grey.400'}
                        >
                          {additionalInfoLen} / {MAX_CHARS}
                        </Text>
                      </Box>
                    </Box>
                  ) : (
                    <Box />
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <Divider orientation="horizontal" borderColor="#E5E5E5" />
        </Accordion>

        {(hasCravingError ||
          hasRelaxedError ||
          hasHappyError ||
          hasPainError ||
          hasRelaxedError ||
          hasSadError ||
          hasStressedError ||
          hasAnxiousError) && (
          <Box m={'10px'} pt={'20px'}>
            <Text color="red.500" fontSize="14px">
              {t('surveyModal.commonError.error')}
            </Text>
          </Box>
        )}

        <Center>
          <Flex direction="row" justifyContent="flex-end">
            {errors && (
              <Flex direction="column" justifyContent="flex-end" fontWeight="bold">
                {/* Successful Form Submission Message */}
                <Text fontSize={16} mb={2} ml={2} color="red.600">
                  {/* {t(errors)} */}
                </Text>
              </Flex>
            )}

            {/* Successful Form Submission Message */}
            {formSubmitted && (
              <Flex direction="column" justifyContent="flex-end" fontWeight="bold">
                <Text fontSize={16} mb={2} ml={2} color="green.600">
                  {t('surveyForm.surveySubmittedSuccess')}
                </Text>
              </Flex>
            )}
            <Flex direction="column" margin={'30px'}>
              {/* Submit Button */}

              <Button
                backgroundColor="#2680D0"
                color="white"
                isDisabled={formHasErrors}
                mt="10px"
                p="10px 28px 10px 28px"
                isLoading={formState.isSubmitting}
                type="submit"
                data-test="submit"
              >
                {t('surveyForm.submitBtn')}
              </Button>
            </Flex>
          </Flex>
        </Center>
      </form>
    </Box>
  );
};

function mapStateToProps(state: RootState) {
  const { selectedLinkedUser } = state.linkedUsers;
  const { creating, error } = state.survey;
  const { todos } = state.todo;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }
  return {
    todos,
    pir: selectedLinkedUser.pir,
    surveyCreated: creating,
    error,
    hasOnboarded: state.onboarding.hasOnboarded,
  };
}

const mapDispatchToProps = {
  createSurvey,
  addAlert,
  createInteraction,
  getToDos,
  updateToDo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SurveyForm);
