import { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  Heading,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { openNewTaskModule, closeNewTaskModule } from './actions';

import AppointmentModule from '../appointments/AppointmentModule';
import MeditationModule from '../meditations/MeditationModule';
import MedicationModule from '../medications/MedicationModule';
import SurveyModule from '../survey/SurveyModule';
import ResourceModule from '../resources/ResourceModule';

interface Props {
  isNewTaskModuleOpen: boolean;
  openNewTaskModule: () => void;
  closeNewTaskModule: () => void;
  isVisible: boolean;
}

const NewTaskModule = ({ isNewTaskModuleOpen, openNewTaskModule, closeNewTaskModule, isVisible }: Props) => {
  const [todoType, setTodoType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!isVisible) closeNewTaskModule();
    else handleCloseModal();
  }, [isVisible]);

  const handleOpenModal = () => {
    openNewTaskModule();
    setTodoType('');
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    document.body.focus(); 
    setTodoType('');
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (todoType !== '') setIsModalOpen(false);
  }, [todoType]);

  return (
    <>
      {todoType === 'survey' && isNewTaskModuleOpen && (
        <SurveyModule isUIVisible={!isVisible} openSurveyModal={todoType === 'survey' && isNewTaskModuleOpen} />
      )}
      {todoType === 'appointments' && isNewTaskModuleOpen && (
        <AppointmentModule
          isPir={true}
          isUIVisible={!isVisible}
          openAppointmentModal={todoType === 'appointments' && isNewTaskModuleOpen}
        />
      )}
      {todoType === 'medications' && isNewTaskModuleOpen && (
        <MedicationModule
          isUIVisible={!isVisible}
          openMedicationModal={todoType === 'medications' && isNewTaskModuleOpen}
        />
      )}
      {todoType === 'meditations' && isNewTaskModuleOpen && (
        <MeditationModule
          isPir={true}
          isUIVisible={!isVisible}
          openMeditationModal={todoType === 'meditations' && isNewTaskModuleOpen}
        />
      )}

      {todoType === 'resource' && isNewTaskModuleOpen && (
        <ResourceModule
          isPir={true}
          isUIVisible={!isVisible}
          openResourceModal={todoType === 'resource' && isNewTaskModuleOpen}
        />
      )}

      <Heading
        paddingStart={5}
        paddingEnd={5}
        as="h4"
        fontSize={14}
        fontWeight="bold"
        color="blue.500"
        cursor="pointer"
        onClick={handleOpenModal}
      >
        Add Task
      </Heading>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create a new task</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select placeholder="Select task" value={todoType} onChange={(e) => setTodoType(e.target.value)} mb={4}>
              <option value="survey">Survey</option>
              <option value="appointments">Appointment</option>
              <option value="medications">Medication</option>
              <option value="meditations">Meditation</option>
              <option value="resource">Resource</option>
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={handleCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isNewTaskModuleOpen: state.newTask.isNewTaskModuleOpen,
});

const mapDispatchToProps = {
  openNewTaskModule,
  closeNewTaskModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTaskModule);
