import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import initReducer from '../modules/initialize/reducer';
import homeReducer from '../modules/home/reducer';
import userReducer from '../modules/user/reducer';
import authReducer from '../modules/auth/reducer';
import linkedUsersReducer from '../modules/linked-users/reducer';
import onboardingReducer from '../modules/onboarding/reducer';
import motivatorReducer from '../modules/motivator/reducer';
import { garminReducer } from './reducers/garmin';
import deviceDataReducer from '../modules/deviceData/reducer';
import accelerometerReducer from '../modules/accelerometer/reducer';
import deviceGarminReducer from '../modules/deviceGarmin/reducer';
import surveyReducer from '../modules/survey/reducer';
import vitalsReducer from './reducers/vitals';
import cravingReducer from '../modules/craving/reducer';
import aggregatesReducer from '../modules/summaryTable/reducer';
import todosReducer from '../modules/todo/reducer';
import adminReducer from './slices/admin';
import freeDateReducer from '../modules/freeDate/reducer';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import invitationReducer from '../modules/invitations/invitationReducer';
import { MiddlewareAPI, Dispatch, AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import newTaskReducer from 'src/modules/new-todo/reducer';

// to make sure it does not get stuck uploading or syncing
const timeoutMiddleware =
  (store: MiddlewareAPI<Dispatch, RootState>) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    const result = next(action);
    const state = store.getState();
    const SYNC_TIMEOUT = 60000;
    const UPLOAD_TIMEOUT = 60000 * 3;
    const now = Date.now();

    if (state.garmin.isSyncing && state.garmin.syncStartTime) {
      if (now - state.garmin.syncStartTime > SYNC_TIMEOUT) {
        console.log('Syncing timed out');
        const date = new Date();
        Sentry.captureException(new Error(`Syncing timed out for Nate at ${date}`));
        store.dispatch({ type: 'GARMIN_SYNC_STOP' });
      }
    }

    if (state.garmin.isUploading && state.garmin.uploadStartTime) {
      if (now - state.garmin.uploadStartTime > UPLOAD_TIMEOUT) {
        const date = new Date();
        const cstDate = date.toLocaleString('en-US', { timeZone: 'America/Chicago' });
        Sentry.captureException(new Error(`Uploading timed out at ${cstDate}`));
        store.dispatch({ type: 'GARMIN_UPLOAD_FAIL' });
      }
    }

    return result;
  };

const rootReducer = combineReducers({
  //device: deviceReducer,
  accelerometer: accelerometerReducer,
  admin: adminReducer,
  auth: authReducer,
  craving: cravingReducer,
  deviceData: deviceDataReducer,
  deviceGarmin: deviceGarminReducer,
  freeDate: freeDateReducer,
  garmin: garminReducer,
  home: homeReducer,
  init: initReducer,
  linkedUsers: linkedUsersReducer,
  motivator: motivatorReducer,
  onboarding: onboardingReducer,
  aggregates: aggregatesReducer,
  survey: surveyReducer,
  todo: todosReducer,
  user: userReducer,
  userInvitations: invitationReducer,
  vitals: vitalsReducer,
  newTask: newTaskReducer,
});

const persistConfig = {
  key: 'root',
  whitelist: ['garmin'],
  stateReconciler: autoMergeLevel2,
  storage,
};
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(thunk, timeoutMiddleware),
  devTools: {
    trace: true,
    traceLimit: 25,
  },
});

export const persistor = persistStore(store);
