import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from '@chakra-ui/react';

import SurveyModal from '../../components/TakeSurveyModal/SurveyModal'; // TODO
import { RootState } from '../../redux/store';
import { createInteraction } from '../interactions/actions';
import { moduleName, interactionType } from '../interactions/constants';
import { wearableSync } from '../../lib/wearable';
import { setSelectedToDo } from '../todo/actions';

import PrimaryButton from '../../components/PrimaryButton';
import { useModal } from 'src/ModalContext';

interface Props {
  isUIVisible?: boolean;
  openSurveyModal?: boolean;
}

const MODAL_NAME = 'survey';

const SurveyModule = ({
  //survey,
  pir,
  createInteraction,
  setSelectedToDo,
  isUIVisible = true,
  openSurveyModal,
}: Props & PropsFromRedux): React.ReactElement => {
  const { t } = useTranslation('survey');

  const { modal, openModal, closeModal } = useModal();

  useEffect(() => {
    if (openSurveyModal) openModal(MODAL_NAME);
  }, [openSurveyModal]);

  const addClosedInteraction = async () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.SURVEY,
      interactionType: interactionType.SURVEY.CLOSED,
    });
    if (window.cordova) {
      try {
        await wearableSync();
      } catch (err: unknown) {
        console.error('Wearable sync error:', err);
      }
    }
  };

  useEffect(() => {
    if (modal === MODAL_NAME) {
      createInteraction({
        pir,
        dateTime: new Date(),
        moduleName: moduleName.SURVEY,
        interactionType: interactionType.SURVEY.OPEN_MODAL,
      });
    }
  }, [modal === MODAL_NAME]);

  const instructList = [t('surveyModule.moduleInstruct1_2'), t('surveyModule.moduleInstruct2_2')];
  return (
    <>
      {isUIVisible && (
        <Box w="100%" h="100%">
          <Flex p={4} direction="column" align="center" justify="center" backgroundColor="grey6.50" w="100%" h="100%">
            <Text fontSize={14} textAlign="center">
              {instructList}
            </Text>
            <Link to={`/home/${MODAL_NAME}`} onClick={() => openModal(MODAL_NAME)}>
              <PrimaryButton mt={3} alignItems="center" zIndex={1} onClick={() => setSelectedToDo(null)}>
                {t('surveyModule.moduleButton')}
              </PrimaryButton>
            </Link>
          </Flex>
        </Box>
      )}

      <SurveyModal
        isOpen={modal === MODAL_NAME}
        onOpen={() => openModal(MODAL_NAME)}
        onClose={() => {
          closeModal();
          addClosedInteraction();
        }}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { selectedLinkedUser } = state.linkedUsers;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }

  return {
    survey: state.survey,
    pir: selectedLinkedUser.pir,
  };
};

const mapDispatchToProps = {
  createInteraction,
  setSelectedToDo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SurveyModule);
