import { Dispatch } from 'react';
import { Action } from 'redux';

export enum NewTaskActionTypes {
  OPEN_NEW_TASK_MODULE = 'OPEN_NEW_TASK_MODULE',
  CLOSE_NEW_TASK_MODULE = 'CLOSE_NEW_TASK_MODULE',
  SET_NEW_TASK_MODULE_STATE = 'SET_NEW_TASK_MODULE_STATE',
}

export type NewTaskActions = OpenNewTaskModuleAction | CloseNewTaskModuleAction | SetNewTaskModuleStateAction;

export interface OpenNewTaskModuleAction extends Action<NewTaskActionTypes.OPEN_NEW_TASK_MODULE> {
  type: NewTaskActionTypes.OPEN_NEW_TASK_MODULE;
}

export const openNewTaskModule = (): OpenNewTaskModuleAction => ({
  type: NewTaskActionTypes.OPEN_NEW_TASK_MODULE,
});

export interface CloseNewTaskModuleAction extends Action<NewTaskActionTypes.CLOSE_NEW_TASK_MODULE> {
  type: NewTaskActionTypes.CLOSE_NEW_TASK_MODULE;
}

export const closeNewTaskModule = (): CloseNewTaskModuleAction => ({
  type: NewTaskActionTypes.CLOSE_NEW_TASK_MODULE,
});

export interface SetNewTaskModuleStateAction extends Action<NewTaskActionTypes.SET_NEW_TASK_MODULE_STATE> {
  isOpen: boolean;
}

export const setNewTaskModuleState = (isOpen: boolean): SetNewTaskModuleStateAction => ({
  type: NewTaskActionTypes.SET_NEW_TASK_MODULE_STATE,
  isOpen,
});

export const toggleNewTaskModule = (isOpen: boolean) => {
  return (dispatch: Dispatch<NewTaskActions>): void => {
    dispatch(setNewTaskModuleState(isOpen));
  };
};
