import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface ModalContextProps {
  modal: string | null;
  openModal: (modalName: string) => void;
  closeModal: () => void;
  setMeditationTodos: React.Dispatch<React.SetStateAction<boolean>>;
  meditationTodos: boolean;
  setAppointmentTodos: React.Dispatch<React.SetStateAction<boolean>>;
  appointmentTodos: boolean;
  setNotificationType: React.Dispatch<React.SetStateAction<string | null>>;
  notificationType: string | null;
}

const ModalContext = createContext<ModalContextProps>({
  modal: null,
  openModal: () => {
    // do nothing
  },
  closeModal: () => {
    // do nothing
  },
  setAppointmentTodos: () => {
    // do nothing
  },
  setMeditationTodos: () => {
    // do nothing
  },
  setNotificationType: () => {
    // do nothing
  },
  meditationTodos: false,
  appointmentTodos: false,
  notificationType: null,
});

export const ModalProvider: React.FC = ({ children }) => {
  const [modal, setModal] = useState<string | null>(null);
  const history = useHistory();
  const [appointmentTodos, setAppointmentTodos] = useState(false);
  const [meditationTodos, setMeditationTodos] = useState(false);
  const [notificationType, setNotificationType] = useState<string | null>(null);
  const location = useLocation();

  const openModal = useCallback((modalName: string) => {
    setModal(modalName);
  }, []);

  const closeModal = useCallback(() => {
    setModal(null);

    if (location.pathname !== '/home') {
      history.push('/home');
    }
  }, []);

  const value = useMemo(
    () => ({
      modal,
      openModal,
      closeModal,
      meditationTodos,
      setMeditationTodos,
      appointmentTodos,
      setAppointmentTodos,
      notificationType,
      setNotificationType,
    }),
    [modal, openModal, closeModal],
  );

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export const useModal = () => useContext(ModalContext);
